import { Button, Form, Input, Space, Spin } from "antd";
import React, { useState } from "react";
import PdfComponent from "./pdf.component";
export default function Step7Component({ contents, inviteEmail, onFinish }) {
  const locationOfSignature = contents?.locationOfSignature;
  const file = contents?.contractUrl;
  return (
    <Spin spinning={false}>
      <div>
        <Space
          style={{
            width: "100%",
            padding: "20px",
          }}
          direction="vertical"
          align="center"
          className="sticky"
        >
          <Button
            type="primary"
            htmlType="submit"
            onClick={() => {
              onFinish();
            }}
          >
            Tải về
          </Button>
        </Space>
        <div
          style={{
            maxWidth: "1024px",
            margin: "auto",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            {PdfComponent({ file, locationOfSignature })}
          </div>
        </div>
      </div>
    </Spin>
  );
}
