import {
  MinusCircleOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  Button,
  Form,
  Input,
  message,
  Select,
  Spin,
  Upload,
  Image,
  Space,
} from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { CREATE_RESOURCE_INTERNAL } from "../../graphql/resourceMutations";
import {
  FIND_ALL_SERVICE,
  FIND_ONE_SERVICE_CMS,
  UPDATE_SERVICE_CMS,
} from "../../graphql/service-cms";
import Editor from "../../components/Editor";
const { TextArea } = Input;

const EditService = () => {
  const [spinning, setSpinning] = useState(false);
  const [type, setType] = useState(null);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const params = useParams();

  const [uploadImage] = useMutation(CREATE_RESOURCE_INTERNAL);

  const [findServiceById, { data, loading }] =
    useLazyQuery(FIND_ONE_SERVICE_CMS);
  const [updateServiceCMS] = useMutation(UPDATE_SERVICE_CMS, {
    refetchQueries: [
      {
        query: FIND_ONE_SERVICE_CMS,
        variables: { id: params?.serviceId },
      },
      {
        query: FIND_ALL_SERVICE,
      },
    ],
  });

  useEffect(() => {
    if (params?.serviceId) {
      findServiceById({
        variables: {
          id: params?.serviceId,
        },
      });
    }
  }, [params]);

  useEffect(() => {
    if (data?.service) {
      form.setFieldsValue({
        id: data?.service.id,
        name: data?.service.name,
        contents: data?.service?.contents,
      });
    }
    setSpinning(false);
  }, [form, data]);
  const getFile = (e) => {
    return e?.file;
  };

  const convertElementFns = async (element, elementIndex) => {
    console.log("convertElementFns", element);
    let imageUrl = "";
    if (element.image) {
      if (element.image?.status === "removed") {
        //
      } else if (element.image?.status === "done") {
        imageUrl = element.image.url;
      } else {
        const createResourceDt = await uploadImage({
          variables: {
            file: element.image,
          },
        });
        imageUrl = createResourceDt?.data?.createResource?.url;
      }
    } else {
      imageUrl = element?.imageUrl ?? "";
    }
    console.log({ element });
    return {
      imageUrl,
      value: element.value,
      id: element.id,
      title: element.title,
      mores: element.mores,
    };
  };

  const convertContentFns = async (content, contentIndex) => {
    let imageUrl = "";
    if (content.image) {
      if (content.image?.status === "removed") {
        //
      } else if (content.image?.status === "done") {
        imageUrl = content.image.url;
      } else {
        const createFeatureDt = await uploadImage({
          variables: {
            file: content.image,
          },
        });
        imageUrl = createFeatureDt?.data?.createResource?.url;
      }
    } else {
      imageUrl = content.imageUrl ?? "";
    }
    const elementFns = [];
    content.elements?.forEach((element, elementIndex) =>
      elementFns.push(convertElementFns(element, elementIndex))
    );
    const elements = await Promise.all(elementFns);
    return {
      id: content.id,
      name: content.name,
      elements: elements,
      imageUrl,
      contentIndex,
    };
  };
  const onFinish = async (values) => {
    console.log("onFinish");
    setSpinning(true);
    try {
      const contentPromises = values?.contents?.map((content, index) =>
        convertContentFns(content, index)
      );
      const contents = await Promise.all(contentPromises);
      const updateServiceCmInput = {
        id: values.id,
        name: values.name,
        contents,
      };
      await updateServiceCMS({
        variables: {
          updateServiceCmInput,
        },
      });
      setSpinning(false);
      message.success("Cập nhật thành công!");
      // navigate("/services");
    } catch (error) {
      message.error(error);
      setSpinning(false);
      return;
    }
  };

  return (
    <Spin tip="Đang xử lý..." spinning={spinning}>
      <div className="">
        <div className="sticky-button">
          <Form.Item>
            <Button
              form="new_service"
              className=" ant-btn-round flex content-center justify-center object-center"
              type="primary"
              htmlType="submit"
            >
              Lưu Dữ Liệu
            </Button>
          </Form.Item>
        </div>

        <div>
          <p className="font-semibold	text-2xl">Sửa dịch vụ</p>
        </div>
        <Form
          name="new_service"
          form={form}
          onFinish={onFinish}
          autoComplete="off"
          layout="vertical"
        >
          <Form.Item
            name={"name"}
            label="Tên dịch vụ"
            rules={[{ required: true, message: "Thiếu" }]}
          >
            <Input showCount placeholder="Tên dịch vụ" />
          </Form.Item>
          <Form.Item
            name={"id"}
            label="Định danh dịch vụ"
            rules={[{ required: true, message: "Thiếu" }]}
          >
            <Input disabled showCount placeholder="Định danh" />
          </Form.Item>
          <>
            <div>
              <p className="text-lg	font-semibold">Nội dung</p>
            </div>
            <Form.List name="contents">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }, contentIndex) => (
                    <div key={key}>
                      <div className="flex w-full">
                        <div className="pr-2">{contentIndex + 1}.</div>
                        <Form.Item
                          name={[name, "name"]}
                          className="w-full"
                          {...restField}
                          rules={[{ required: true, message: "Thiếu" }]}
                        >
                          <TextArea
                            autoSize={{ minRows: 2, maxRows: 15 }}
                            showCount
                            placeholder="Nội dung"
                          />
                        </Form.Item>

                        <div className="w-1/12 pl-2">
                          <MinusCircleOutlined onClick={() => remove(name)} />
                        </div>
                      </div>
                      <Form.Item
                        className="w-8/12 border-0"
                        name={[name, "id"]}
                        label="ID (Lưu ý: Không thay đổi)"
                        rules={[{ required: true, message: "Thiếu" }]}
                      >
                        <Input
                          showCount
                          placeholder="Định danh thành phần con"
                        />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        className="w-10/12 border-0 flex"
                        name={[name, "image"]}
                        getValueFromEvent={getFile}
                      >
                        <Upload
                          name="logo"
                          beforeUpload={(file) => {
                            return false;
                          }}
                          listType="picture"
                          className="ml-4"
                          maxCount={1}
                          defaultFileList={
                            data?.service.contents &&
                            data?.service.contents[contentIndex]?.imageUrl
                              ? [
                                  {
                                    status: "done",
                                    url: data?.service.contents[contentIndex]
                                      ?.imageUrl,
                                  },
                                ]
                              : []
                          }
                        >
                          <Button icon={<UploadOutlined />}>
                            Ảnh nội dung
                          </Button>
                        </Upload>
                      </Form.Item>

                      {data?.service.contents[contentIndex]?.imageUrl && (
                        <p className="font-bold">
                          Link ảnh:{" "}
                          {data?.service.contents[contentIndex]?.imageUrl}
                        </p>
                      )}

                      <Image
                        width={800}
                        src={
                          data?.service.contents[contentIndex]?.imageUrl || ""
                        }
                      />
                      <Form.List name={[name, "elements"]}>
                        {(
                          fields,
                          { add: addElement, remove: removeElement }
                        ) => (
                          <>
                            {fields?.map(
                              ({ key, name, ...restField }, elementIndex) => {
                                return (
                                  <div
                                    key={key}
                                    style={{
                                      border: "1px solid #ccc",
                                      minWidth: "1050",
                                    }}
                                  >
                                    <div className="flex w-full pl-20">
                                      <div className="pl-4">
                                        {elementIndex + 1}.
                                      </div>
                                      <div className="w-6/12 border-0">
                                        <Form.Item
                                          {...restField}
                                          className="w-12/12 border-0 py-2 px-2"
                                          name={[name, "value"]}
                                          rules={[
                                            {
                                              required: true,
                                              message: "Thiếu",
                                            },
                                          ]}
                                        >
                                          <Editor
                                            placeholder={`Thành phần ${
                                              elementIndex + 1
                                            }`}
                                          />
                                        </Form.Item>
                                        <Form.Item
                                          className="w-12/12 border-0"
                                          name={[name, "id"]}
                                          label="ID hoặc ID của bài test (Lưu ý: Không thay đổi)"
                                        >
                                          <Input
                                            showCount
                                            placeholder="Định danh"
                                          />
                                        </Form.Item>
                                        <Form.Item
                                          className="w-12/12 border-0"
                                          name={[name, "title"]}
                                          label="Bổ sung"
                                        >
                                          <Input
                                            showCount
                                            placeholder="Bổ sung"
                                          />
                                        </Form.Item>
                                        <h4>Thêm nữa</h4>
                                        <Form.List name={[name, "mores"]}>
                                          {(
                                            fields,
                                            { add: addMore, remove: removeMore }
                                          ) => (
                                            <>
                                              {fields?.map(
                                                (
                                                  { key, name, ...restField },
                                                  moreIndex
                                                ) => {
                                                  return (
                                                    <div key={key}>
                                                      <div className="flex w-full">
                                                        <div className="w-1/12 flex">
                                                          <div className="">
                                                            {moreIndex + 1}.
                                                          </div>
                                                        </div>
                                                        <div>
                                                          <Form.Item
                                                            {...restField}
                                                            className="w-11/12 border-0"
                                                            name={[name, "key"]}
                                                          >
                                                            <Input
                                                              placeholder={`Tên ${
                                                                moreIndex + 1
                                                              }`}
                                                            />
                                                          </Form.Item>
                                                          <Form.Item
                                                            {...restField}
                                                            className="w-11/12 border-0"
                                                            name={[
                                                              name,
                                                              "value",
                                                            ]}
                                                          >
                                                            <Editor
                                                              placeholder={`Giá trị ${
                                                                moreIndex + 1
                                                              }`}
                                                            />
                                                          </Form.Item>
                                                        </div>
                                                        <div className="w-1/12 pl-2">
                                                          <MinusCircleOutlined
                                                            onClick={() =>
                                                              removeMore(name)
                                                            }
                                                          />
                                                        </div>
                                                      </div>
                                                    </div>
                                                  );
                                                }
                                              )}
                                              <Form.Item>
                                                <Button
                                                  type="dashed"
                                                  onClick={() => addMore()}
                                                  icon={<PlusOutlined />}
                                                >
                                                  Khác
                                                </Button>
                                              </Form.Item>
                                            </>
                                          )}
                                        </Form.List>
                                      </div>

                                      <div className="w-6/12 p-2">
                                        <Form.Item
                                          {...restField}
                                          className="w-10/12 border-0 flex"
                                          name={[name, "image"]}
                                          getValueFromEvent={getFile}
                                        >
                                          <Upload
                                            name="logo"
                                            beforeUpload={(file) => {
                                              return false;
                                            }}
                                            listType="picture"
                                            className="ml-4"
                                            maxCount={1}
                                            defaultFileList={
                                              data?.service?.contents[
                                                contentIndex
                                              ]?.elements &&
                                              data?.service?.contents[
                                                contentIndex
                                              ]?.elements[elementIndex]
                                                ?.imageUrl
                                                ? [
                                                    {
                                                      status: "done",
                                                      url: data?.service
                                                        ?.contents[contentIndex]
                                                        ?.elements[elementIndex]
                                                        ?.imageUrl,
                                                    },
                                                  ]
                                                : []
                                            }
                                          >
                                            <Button icon={<UploadOutlined />}>
                                              Ảnh thành phần {elementIndex + 1}
                                            </Button>
                                          </Upload>
                                        </Form.Item>
                                        <div>
                                          <Image
                                            style={{
                                              minWidth: 100,
                                            }}
                                            src={
                                              (data?.service?.contents[
                                                contentIndex
                                              ]?.elements &&
                                                data?.service?.contents[
                                                  contentIndex
                                                ]?.elements[elementIndex]
                                                  ?.imageUrl) ||
                                              ""
                                            }
                                          />
                                        </div>
                                      </div>

                                      <div className="pl-2 w-1/12">
                                        <MinusCircleOutlined
                                          onClick={() => removeElement(name)}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                );
                              }
                            )}
                            <Form.Item>
                              <Button
                                className="ml-24"
                                type="dashed"
                                onClick={() => addElement()}
                                icon={<PlusOutlined />}
                              >
                                Thêm thành phần con
                              </Button>
                            </Form.Item>
                          </>
                        )}
                      </Form.List>
                    </div>
                  ))}
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      Thêm nội dung
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </>
        </Form>
      </div>
    </Spin>
  );
};

EditService.propTypes = {};

EditService.defaultProps = {};

export default EditService;
