import { Button, Form, Input, Space, Spin } from "antd";
import React, { useEffect, useState } from "react";
import PdfComponent from "./pdf.component";
import ModalComponent from "./modal/modal.component";
import ESignComponent from "./esign/esign.component";
import ModalApplication from "./modal/modal.application";
import ESignApplication from "./esign/esign.application";
import Step6ModalComponent from "./step6.modal.component";
export default function Step6Component({
  existedSign,
  contents,
  inviteEmail,
  onFinish,
  LOCKED,
}) {
  const locationOfSignature = contents?.locationOfSignature;
  const signer = locationOfSignature?.find(
    ({ email }) => email === inviteEmail
  );
  const isDisableModal = LOCKED?.[inviteEmail] || false;
  const file = contents?.contractUrl;

  const {
    modalProps: { open, isLock },
    showModal,
    showModalConfirm,
    handleCancel,
    handleOk,
    handleModalReset,
  } = ModalApplication();

  const wrapperHandleReset = () => {
    handleModalReset(() => window.location.reload());
  };

  const {
    signImg,
    setExistedData,
    setSignImg,
    sigPad,
    setSigPad,
    clear,
    handleSignatureEnd,
  } = ESignApplication();
  const propsESignApplication = {
    signImg,
    setExistedData,
    setSignImg,
    sigPad,
    setSigPad,
    clear,
    handleSignatureEnd,
  };
  // END TODO:
  return (
    <Spin spinning={false}>
      <div>
        <Step6ModalComponent
          signer={signer}
          existedSign={existedSign}
          onFinish={onFinish}
          open={open}
          isLock={isLock}
          handleCancel={handleCancel}
          handleOk={handleOk}
          wrapperHandleReset={wrapperHandleReset}
          {...propsESignApplication}
        />

        {/*<ESignModalComponent isLock={isLock} />*/}
        <Space
          style={{
            width: "100%",
            padding: "20px",
          }}
          direction="vertical"
          align="center"
          className="sticky"
        >
          <Button
            type="primary"
            htmlType="submit"
            disabled={!signImg || isDisableModal}
            onClick={() => {
              showModalConfirm();
            }}
          >
            Hoàn tất
          </Button>
        </Space>
        <div
          style={{
            maxWidth: "1024px",
            margin: "auto",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            {PdfComponent({
              file,
              locationOfSignature,
              signer,
              showModal: isDisableModal ? () => {} : showModal,
              signImg,
            })}
          </div>
        </div>
      </div>
    </Spin>
  );
}
