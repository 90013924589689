import { useLazyQuery, useMutation } from '@apollo/client';
import { Form, Input, message, Modal, Select, Spin, Button, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

import React, { useEffect, useState } from 'react'
import { FIND_QUIZ_RESULT_MAPPTING_BY_ID, UPDATE_QUIZ_MAPPING, FIND_ALL_QUIZ_RESULT_MAPPINGS } from '../../graphql/quiz-result-mappting';
import {CREATE_RESOURCE} from "../../graphql/resourceMutations";

const EditQuizResultMappings = ({
  setIsShowModalUpdate,
  isShowModalUpdate,
  quizMappingId,
  dataList
}) => {
  const [uploadReport] = useMutation(CREATE_RESOURCE);
  const { TextArea } = Input;
  const [spinning, setSpinning] = useState(false);
  const [form] = Form.useForm();
  const [findQuizMappingById, { data, loading }] = useLazyQuery(FIND_QUIZ_RESULT_MAPPTING_BY_ID);
  const [updateQuizMapping] = useMutation(UPDATE_QUIZ_MAPPING, {
    refetchQueries: [{
      query: FIND_ALL_QUIZ_RESULT_MAPPINGS
    }]
  });

  useEffect(() => {
    if (quizMappingId) {
      findQuizMappingById({
        variables: {
          id: quizMappingId
        }
      })
    }
  }, [quizMappingId, findQuizMappingById]);
  useEffect(() => {
    if (data?.quizMapping) {
      form.setFieldsValue({
        description: data?.quizMapping.description,
        value: data?.quizMapping.value,
        quizId: data?.quizMapping?.quiz?.name,
        pdfFile: data?.quizMapping?.urlReportS3,
        pdfFileShadow: data?.quizMapping?.urlReportS3,
      });
    }
    setSpinning(false);
  }, [form, data, data?.quizMapping?.urlReportS3]);
  const onFinish = async (values) => {
    setSpinning(true);
    try {
      const { urlReportS3 } = await convertReportObject(values)
      await updateQuizMapping({
        variables: {
          updateQuizMappingInput: {
            quizMappingId: data?.quizMapping.quizMappingId,
            description: values.description,
            value: values.value,
            quizId: data?.quizMapping.quizId,
            isReport: data?.quizMapping.isReport,
            urlReportS3
          }
        }
      });
      message.success("Update success!");
      setSpinning(false);
    } catch (error) {
      message.error(error);
      setSpinning(false);
    }
  }
  const handleOk = () => {
    form.submit();
    setIsShowModalUpdate(false);
  }

  const handleCancel = () => {
    setIsShowModalUpdate(false);
  }

  const normFile = (e) => {
    return e?.file;
  };

  const convertReportObject = async (attachFile) => {
    let urlReportS3 = '';
    if (attachFile.pdfFile) {
      const report = await uploadReport({
        variables: {
          file: attachFile.pdfFile
        }
      });
      urlReportS3 = report?.data?.createResource?.url
      return { urlReportS3 }
    } else {
      return { urlReportS3: '' }
    }
  }

  const getFileName = (pathFileS3) => {
    return pathFileS3?.split('/resource/')[1];
  }
  return (
    <Spin tip="Loading..." spinning={spinning || loading}>
      <div className='mt-10'>
      <Modal title="Update quiz result mapping" open={isShowModalUpdate} onOk={handleOk} onCancel={handleCancel}>
        <div>
          <Form
            name="quiz_mapping"
            form={form}
            onFinish={onFinish}
            autoComplete="off"
            layout='vertical'
          >
            <Form.Item name="quizId" label="Quiz" rules={[{ required: true, message: 'Missing Quiz name' }]}>
            <Select>
              {
                dataList?.mappings?.map(quiz => <Select.Option key={quiz.quizMappingId} value={quiz.quizId}>{quiz.quiz.name}</Select.Option>)
              }
            </Select>
          </Form.Item>
            <Form.Item name="description" label="Description" rules={[{ required: true, message: 'Missing Quiz description' }]}>
            <TextArea />
          </Form.Item>
          <Form.Item name="value" label="Value" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
            <Form.Item
              name='pdfFile'
              getValueFromEvent={(e) => e?.file}
            >
              <Upload
                accept=".pdf"
                beforeUpload={(file) => {
                  return false;
                }}
                fileList={[{
                  uid: '1',
                  name: getFileName(data?.quizMapping?.urlReportS3),
                  url: data?.quizMapping?.urlReportS3,
                }]}
                name="pdfFileShadow"
                className='ml-4'
                maxCount={1}>
                <Button className='w-full' icon={<UploadOutlined />}>Upload</Button>
              </Upload>
            </Form.Item>
          </Form>
        </div>
        </Modal>
      </div>
    </Spin>
  )
}

export default EditQuizResultMappings
