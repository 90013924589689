import { useMutation, useQuery } from '@apollo/client';
import {Button, Form, Input, message, Modal, Select, Upload,} from 'antd';
import { FIND_ALL_QUIZ } from '../../graphql/quiz';
import { CREATE_QUIZ_RESULT_MAPPINGS, FIND_ALL_QUIZ_RESULT_MAPPINGS } from '../../graphql/quiz-result-mappting';
import { useModalForm } from 'sunflower-antd';
import {UploadOutlined} from "@ant-design/icons";
import {CREATE_RESOURCE} from "../../graphql/resourceMutations";

const CreateQuizResultMapping = ({
  setLoading,
  setIsShowModalCreate,
  isShowModalCreate,
}) => {
  const [uploadReport] = useMutation(CREATE_RESOURCE);
  const [form] = Form.useForm();
  const [createQuizResultMapping] = useMutation(CREATE_QUIZ_RESULT_MAPPINGS, {
    refetchQueries: [{ query: FIND_ALL_QUIZ_RESULT_MAPPINGS }],
  });
  const { data } = useQuery(FIND_ALL_QUIZ, { variables: { findAllQuizInput: { skip: 0, take: 999 } } })
  const {
    modalProps,
    formProps,
  } = useModalForm({
    defaultVisible: false,
    autoSubmitClose: true,
    autoResetForm: true,
    async submit(values) {
      try {
        const { urlReportS3 } = await convertReportObject(values)
        createQuizResultMapping({
          variables: {
            createQuizMappingInput: {
              quizId: values.quizId,
              description: values.description,
              value: values.value,
              urlReportS3
            }
          }
        });
        setLoading(false);
        setIsShowModalCreate(false);
      } catch (error) {
        message.error(error);
        setLoading(false);
        return;
      }
    },
    form,
  });
  const convertReportObject = async (attachFile) => {
    let urlReportS3 = '';
    if (attachFile.pdfFile) {
      const report = await uploadReport({
        variables: {
          file: attachFile.pdfFile
        }
      });
      urlReportS3 = report?.data?.createResource?.url
      return { urlReportS3 }
    } else {
      return { urlReportS3: '' }
    }
  }
  const handleCancel = () => {
    setIsShowModalCreate(false);
    form.setFieldsValue({
      quizId: '',
      description: '',
      value: '',
    });
  }

  return (
    <div className='w-full h-1000'>
      <Modal {...modalProps} title="Create quiz result mapping" open={isShowModalCreate} onCancel={handleCancel}>
        <Form
          layout='vertical'
          {...formProps}
        >
          <Form.Item name="quizId" label="Quiz" rules={[{ required: true }]}>
            <Select>
              {
                data?.quizs?.map(quiz => <Select.Option key={quiz.quizId} value={quiz.quizId}>{quiz.name}</Select.Option>)
              }
            </Select>
          </Form.Item>
          <Form.Item name="description" label="Description" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name="value" label="Value" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item
            name='pdfFile'
            getValueFromEvent={(e) => e?.file}
          >
            <Upload
              accept=".pdf"
              beforeUpload={(file) => {
                return false;
              }}
              name="pdfFileShadow"
              className='ml-4'
              maxCount={1}>
              <Button className='w-full' icon={<UploadOutlined />}>Upload</Button>
            </Upload>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  )
};

CreateQuizResultMapping.propTypes = {
};

CreateQuizResultMapping.defaultProps = {
};

export default CreateQuizResultMapping;
