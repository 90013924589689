import SignatureCanvas from "react-signature-canvas";
import React from "react";
import { AREA_SIGNATURE } from "../../../../../../constants/econtract.constant";
import { Space } from "antd";

export default function ESignComponent({
  setExistedData,
  existedSign,
  setSignImg,
  sigPad,
  setSigPad,
  handleSignatureEnd,
}) {
  return (
    <>
      <div
        onClick={() => {
          setExistedData(existedSign);
        }}
        className="flex justify-center"
      >
        {existedSign && (
          <img
            style={{
              width: AREA_SIGNATURE.width,
              height: AREA_SIGNATURE.height,
              maxWidth: AREA_SIGNATURE.width,
            }}
            alt="signature"
            src={existedSign}
          />
        )}
      </div>

      <div className="sigContainer">
        <SignatureCanvas
          data={existedSign}
          canvasProps={{ className: "sigPad" }}
          onEnd={() => handleSignatureEnd()}
          ref={(ref) => {
            // sigPad = ref;
            setSigPad(ref);
          }}
        />
      </div>
    </>
  );
}
