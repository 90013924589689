import { useLocation } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  SUBMIT_SIGNATURE_E_CONTRACT,
  UPSERT_E_CONTRACT,
  VERIFY_E_CONTRACT_BY_TOKEN,
} from "../../../../../graphql/e-contract";
import {
  QUERY_ID,
  QUERY_TOKEN,
} from "../../../../../constants/econtract.constant";
import { Form, message } from "antd";
import { useNavigate } from "react-router-dom";
import { useQueryUtil } from "../../../../../common/util";
import RenderPDFEContract from "../../../../../common/econtract.util";

export function FinishStep7Application(contractId, contents) {
  const onFinish = async () => {
    console.log("onFinish");
    const pdfBytes = await RenderPDFEContract(
      contents?.contractUrl,
      contents?.locationOfSignature
    );
    let file = new Blob([pdfBytes], { type: "application/pdf" });
    let fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  };
  return { onFinish };
}
