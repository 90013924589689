import React, { useEffect, useState } from "react";
import { Button, Modal, Space, Spin, Table, Tag } from "antd";
import { useMutation, useQuery } from "@apollo/client";
import {
  FIND_ALL_E_CONTRACT,
  REMOVE_E_CONTRACT,
} from "../../graphql/e-contract";
import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import * as moment from "moment";
import { useNavigate } from "react-router-dom";
import { FIND_ALL_REFERRAL, REMOVE_REFERRAL } from "../../graphql/referral";
import TableSort from "../../components/TableSort";

export default function ListReferral() {
  const [spinning, setSpinning] = useState(false);
  // const [filteredInfo, setFilteredInfo] = useState({});
  // const [sortedInfo, setSortedInfo] = useState({});
  // const handleChange = (pagination, filters, sorter) => {
  //   console.log("pagination", pagination);
  //   console.log("filters", filters);
  //   console.log("sorter", sorter);
  //   setFilteredInfo(filters);
  //   setSortedInfo(sorter);
  // };
  // const clearFilters = () => {
  //   setFilteredInfo({});
  // };
  // const clearAll = () => {
  //   setFilteredInfo({});
  //   setSortedInfo({});
  // };
  const [removeReferral] = useMutation(REMOVE_REFERRAL, {
    refetchQueries: [
      {
        query: FIND_ALL_REFERRAL,
        variables: { findAllReferralInput: { skip: 0, take: 999 } },
      },
    ],
    onCompleted: () => {
      setSpinning(false);
    },
  });
  const { data, loading } = useQuery(FIND_ALL_REFERRAL, {
    variables: { findAllReferralInput: { skip: 0, take: 999 } },
  });
  console.log("data", data);
  const navigate = useNavigate();
  useEffect(() => {
    setSpinning(loading);
  }, [loading]);

  const factoryFilter = (array, key) => {
    // data?.referrals;
    const items = array?.map((item) => item[key]);
    const uniqueItems = [...new Set(items)];
    return uniqueItems.map((item) => {
      return {
        text: item,
        value: item,
      };
    });
  };

  const columns = [
    {
      title: "No.",
      key: "index",
      width: "5%",
      render: (_value, _item, index) => index + 1,
    },
    {
      title: "MÃ CODE",
      dataIndex: "referralCode",
      key: "referralCode",
      width: "25%",
      customFilter: true,
    },
    // {
    //   title: "Is Resolved",
    //   dataIndex: "isResolved",
    //   key: "isResolved",
    //   width: "15%",
    //   render: (value) => {
    //     if (value) {
    //       return <Tag color="success">TRUE</Tag>;
    //     }
    //     return <Tag color="warning">FALSE</Tag>;
    //   },
    // },
    {
      title: "Bài Test",
      dataIndex: "quizName",
      key: "quizName",
      width: "25%",
      customFilter: true,
    },
    {
      title: "Tên",
      dataIndex: "fullName",
      key: "fullName",
      width: "25%",
      customFilter: true,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: "25%",
      customFilter: true,
    },
    {
      title: "Ngày sinh",
      dataIndex: "dayOfBirth",
      key: "dayOfBirth",
      render: (dayOfBirth) =>
        // moment(Date.parse(dayOfBirth)).format("DD/MM/YYYY hh:mm:ss"),
        {
          if (!dayOfBirth) return " ";
          return moment(Date.parse(dayOfBirth)).format("DD/MM/YYYY");
        },
    },
    {
      title: "SĐT",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      width: "25%",
    },
    {
      title: "Thời gian",
      dataIndex: "created_at",
      key: "created_at",
      render: (created_at) =>
        moment(Date.parse(created_at)).format("DD/MM/YYYY hh:mm:ss"),
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      width: "10%",
      render: (_value, item) => {
        return (
          <>
            <DeleteOutlined
              onClick={() => handleDelete(item)}
              className="mr10"
            />
          </>
        );
      },
    },
  ];
  const handleDelete = (item) => {
    Modal.confirm({
      title: `Cẩn thận xóa`,
      icon: <ExclamationCircleOutlined />,
      content: `Bạn có chắc chắn xóa thẻ có mã code "${item?.referralCode}" trên bài "${item?.quizName}" của người dùng 
      "${item?.email}" không?`,
      okText: "Xóa",
      cancelText: "Hủy xóa",
      onOk: () => {
        setSpinning(true);
        removeReferral({
          variables: {
            id: item.id,
          },
        });
      },
    });
  };
  return <TableSort data={data?.referrals} columns={columns} />;
}
