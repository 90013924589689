import React from "react";
import Draggable from "react-draggable";

export default class DraggableComponent extends React.Component {
  state = {
    activeDrags: 0,
    deltaPosition: {
      x: 0, y: 0
    },
    controlledPosition: {
      x: -400, y: 200
    }
  };

  handleDrag = (e, ui) => {
    const {x, y} = this.state.deltaPosition;
    this.setState({
      deltaPosition: {
        x: x + ui.deltaX,
        y: y + ui.deltaY,
      }
    });
    this.props.setsignImgXY({
      x: x + ui.deltaX,
      y: y + ui.deltaY,
    })
  };

  onStart = () => {
    this.setState({activeDrags: ++this.state.activeDrags});
  };

  onStop = () => {
    this.setState({activeDrags: --this.state.activeDrags});
  };

  onDrop = (e) => {
    window.__pageNumber = -1
    const draggableSignAreaElement = document.getElementById("draggable-sign-area")
    const { top, left, bottom, right } = draggableSignAreaElement.getBoundingClientRect()
    const pageCanvasElemements = document.getElementsByClassName("react-pdf__Page__canvas")
    for (let index = 0; index < pageCanvasElemements.length; index++) {
      const pageCanvas = pageCanvasElemements[index].getBoundingClientRect()
      if (
        top <= pageCanvas.bottom && top >= pageCanvas.top && left >= pageCanvas.left && left <= pageCanvas.right
      && bottom <= pageCanvas.bottom && bottom >= pageCanvas.top && right >= pageCanvas.left && right <= pageCanvas.right
      ) {
        window.__pageNumber = Number(pageCanvasElemements[index].parentElement.dataset.pageNumber)
      }
    }
  };

  nodeRef = React.createRef()
  render() {
    const dragHandlers = {onStart: this.onStart, onStop: this.onStop};
    const {deltaPosition} = this.state;
    const { signImg } = this.props;
    console.log({x: deltaPosition.x.toFixed(0), y: deltaPosition.y.toFixed(0)})
    return (
      <Draggable  nodeRef={this.nodeRef}  handle="strong" {...dragHandlers} onDrag={this.handleDrag} onStop={this.onDrop}>
        <div id="draggable-sign-area" style={{ position: 'relative', zIndex: 1000 }} ref={this.nodeRef} className="box no-cursor">

          {!signImg
            ? <strong className="cursor"><div>Chọn vùng ký</div></strong>
            : null}
          {signImg
            ? <img className="sigImage" alt='signature'
                   src={signImg} />
            : null}
        </div>

      </Draggable>


    )
  }
}
