import React, { useEffect, useState } from "react";
import { Modal, Spin, Table, Tag } from "antd";
import {
  FetchDataEContractListApplication,
  HandleCRUDTableEContractListApplication,
  ParseDataToTableEContractListApplication,
} from "./application/list.application";
import { ColumnsListComponentEContract } from "./components/list.component";

export default function ListEContract() {
  const { data, loading } = FetchDataEContractListApplication();
  const { eContracts } = ParseDataToTableEContractListApplication(
    data?.eContracts
  );
  console.log("eContracts", eContracts);
  const { handleDelete, handleUpdate } =
    HandleCRUDTableEContractListApplication();
  const { columns } = ColumnsListComponentEContract({
    handleUpdate,
    handleDelete,
  });
  return (
    <div>
      <Spin spinning={loading}>
        <Table dataSource={eContracts || []} columns={columns} />
      </Spin>
    </div>
  );
}
