import { useLocation } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  SUBMIT_SIGNATURE_E_CONTRACT,
  UPSERT_E_CONTRACT,
  VERIFY_E_CONTRACT_BY_TOKEN,
} from "../../../../../graphql/e-contract";
import {
  ERROR_MAPPING,
  QUERY_ID,
  QUERY_TOKEN,
} from "../../../../../constants/econtract.constant";
import { Form, message } from "antd";
import { useNavigate } from "react-router-dom";
import { useQueryUtil } from "../../../../../common/util";

export function FetchDataStep6Application() {
  const [verifyEContractByToken, { error, loading }] = useLazyQuery(
    VERIFY_E_CONTRACT_BY_TOKEN
  );

  const [response, setResponse] = useState({});

  let query = useQueryUtil();
  useEffect(() => {
    const token = query.get(QUERY_TOKEN);
    if (token) {
      console.log("token", token);
      verifyEContractByToken({
        variables: {
          token,
        },
      }).then(({ data }) => {
        setResponse(data);
      });
    }
  }, [query.get(QUERY_TOKEN)]);

  if (error) {
    message.error(ERROR_MAPPING[error.message] || error.message);
  }

  return { data: response, loading };
}

export function FinishStep6Application(contractId, contents) {
  const navigate = useNavigate();
  let query = useQueryUtil();
  const [submitSignatureEContract] = useMutation(SUBMIT_SIGNATURE_E_CONTRACT, {
    refetchQueries: [
      {
        query: VERIFY_E_CONTRACT_BY_TOKEN,
        variables: { token: query.get(QUERY_TOKEN) },
      },
    ],
  });

  const onFinish = async ({ signImg, email }, callback = () => {}) => {
    const locationOfSignature = contents?.locationOfSignature.map((signer) => {
      const isMap = signer?.email === email;
      if (isMap)
        return {
          ...signer,
          signImg,
        };
      return signer;
    });
    try {
      await submitSignatureEContract({
        variables: {
          submitSignatureEContractInput: {
            id: contractId,
            contents: {
              locationOfSignature,
            },
            email,
            existedSign: signImg,
          },
        },
      });
      message.success("Update success!");
      callback();
    } catch (error) {
      message.error(error);
    }
  };
  return { onFinish };
}
