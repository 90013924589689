import { Button } from "antd";
import React from "react";
import ESignComponent from "./esign/esign.component";
import ModalComponent from "./modal/modal.component";
import ESignApplication from "./esign/esign.application";
import ModalApplication from "./modal/modal.application";

export default function Step6ModalComponent({
  open,
  isLock,
  handleCancel,
  handleOk,
  wrapperHandleReset,
  signer,
  existedSign,
  signImg,
  onFinish,
  setExistedData,
  setSignImg,
  sigPad,
  setSigPad,
  clear,
  handleSignatureEnd,
}) {
  const getModalForDrawSignature = () => {
    const buttons = [
      <Button key="back" onClick={clear}>
        Xóa chữ ký
      </Button>,
      <Button key="submit" type="primary" onClick={handleOk}>
        Xác nhận
      </Button>,
    ];

    const children = ESignComponent({
      setExistedData,
      existedSign,
      setSignImg,
      sigPad,
      setSigPad,
      handleSignatureEnd,
    });
    return (
      <ModalComponent
        handleCancel={handleCancel}
        handleOk={handleOk}
        open={open}
        footer={buttons}
      >
        {children}
      </ModalComponent>
    );
  };

  const getModalForConfirmSignature = () => {
    const buttons = [
      <Button key="back" onClick={wrapperHandleReset}>
        Hủy
      </Button>,
      <Button
        key="submit"
        type="primary"
        onClick={() =>
          onFinish({ signImg, email: signer?.email }, () => {
            handleCancel();
          })
        }
      >
        Xác nhận
      </Button>,
    ];

    const children = (
      <h1> Bạn sẽ không thể thay đổi chữ ký sau khi bấm Xác Nhận</h1>
    );

    return (
      <ModalComponent
        handleCancel={handleCancel}
        handleOk={handleOk}
        open={open}
        footer={buttons}
      >
        {children}
      </ModalComponent>
    );
  };

  // let modal = getModalForDrawSignature();
  let modal = getModalForDrawSignature();
  if (isLock) {
    modal = getModalForConfirmSignature();
  }
  return modal;
}
