import { useState } from "react";

export default function ESignApplication() {
  const [signImg, setSignImg] = useState(null);
  const [sigPad, setSigPad] = useState({});
  const clear = () => {
    sigPad.clear();
    setSignImg(null);
  };

  const setExistedData = (existedSign) => {
    sigPad.fromDataURL(existedSign);
    setSignImg(existedSign);
  };

  const capture = () => {
    if (window.__pageNumber === -1) {
      alert("Bạn chưa chọn vùng chữ ký ở tài liệu ký");
      return;
    }
    setSignImg(
      sigPad.getCanvas().toDataURL("image/png", {
        pixelRatio: 2,
      })
    );
  };

  const handleSignatureEnd = () => {
    if (window.__pageNumber === -1) {
      alert("Bạn chưa chọn vùng chữ ký ở tài liệu ký");
      return;
    }
    setSignImg(
      sigPad.getCanvas().toDataURL("image/png", {
        pixelRatio: 10,
      })
    );
  };
  return {
    setExistedData,
    signImg,
    sigPad,
    setSigPad,
    setSignImg,
    clear,
    capture,
    handleSignatureEnd,
  };
}
