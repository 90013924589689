import { Button, Form, Space, Spin } from "antd";
import React from "react";
import { InboxOutlined } from "@ant-design/icons";
import Dragger from "antd/es/upload/Dragger";
import DocumentContract from "../../../../../components/DocumentContract";
import ButtonsStep2Component from "./buttons-step2.component";

export default function Step2Component({
  data,
  loading,
  form,
  prev,
  onFinish,
  uploadFileContactOnDrop,
  contractUrl,
}) {
  // if (!data) return <></>;
  const renderUploadContractComponent = () => {
    return (
      <Dragger
        name="logo"
        beforeUpload={(file) => {
          uploadFileContactOnDrop(file);
          return false;
        }}
        listType="picture"
        className="ml-4"
        maxCount={1}
      >
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">
          Kéo thả tài liệu của bạn hoặc bấm vào đây để tải lên
        </p>
        <p className="ant-upload-hint">Định dạng được hỗ trợ: .pdf</p>
      </Dragger>
    );
  };
  return (
    <Spin spinning={loading}>
      <div>
        {renderUploadContractComponent()}
        <Space
          style={{
            width: "100%",
            padding: "20px",
          }}
          direction="vertical"
          align="center"
        >
          {ButtonsStep2Component({
            disabled: !contractUrl,
            prev,
            onFinish,
          })}
        </Space>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
          }}
        >
          <DocumentContract url={contractUrl} />
        </div>
      </div>
    </Spin>
  );
}
