// import "./show-pdf-origin.component.css";
import React, { useState } from "react";
import { Document, Page } from "react-pdf";
import { PAGE_PDF_SIZE } from "../../../../../../constants/econtract.constant";

export default function ShowPdfOriginComponent({ file }) {
  const [numPages, setNumPages] = useState(null);

  async function onDocumentLoadSuccess(successEvent) {
    const { numPages } = successEvent;
    setNumPages(numPages);
  }

  function onPageRenderSuccess(props) {}

  const { width, height } = PAGE_PDF_SIZE;

  return (
    <div id="e-contract-doc" style={{ overflow: "auto" }}>
      <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
        {Array.apply(null, Array(numPages))
          .map((x, i) => i + 1)
          .map((pageIndex) => (
            <div key={String(pageIndex)} className="border-solid">
              <Page
                className={`page-${pageIndex} drop-target`}
                width={width}
                height={height}
                style={{
                  flexDirection: "row",
                  backgroundColor: "#E4E4E4",
                }}
                onRenderSuccess={onPageRenderSuccess}
                pageIndex={pageIndex - 1}
              ></Page>
            </div>
          ))}
      </Document>
    </div>
  );
}
