import "./test-show-pdf.css";
import React, { useState } from "react";
import { Document, Page } from "react-pdf";
export default function TestShowPdf({
  file,
  signImg,
  signImgXY,
  __pageNumber,
}) {
  const [numPages, setNumPages] = useState(null);

  async function onDocumentLoadSuccess(successEvent) {
    console.log("onDocumentLoadSuccess");
    const { numPages } = successEvent;
    // console.log({successEvent})
    // const data = await successEvent.getData()
    // const blob = new Blob([data], {type: 'application/pdf'})
    // saveAs(blob, 'document_name.pdf')
    setNumPages(numPages);

    // const importPDFCanvas = document.querySelector('.import-pdf-page canvas');
    // const pdfAsImageSrc = importPDFCanvas.toDataURL()
  }

  function onPageRenderSuccess(props) {
    // console.log('onPageRenderSuccess')
    // console.log({props})
    // const { _pageIndex } = props;
    // const pdfAsImageSrc = importPDFCanvas.toDataURL();
    // console.log({pdfAsImageSrc})
  }

  function handleScroll(e) {
    // console.log('handleScroll', e)
  }

  const elementPage = document.getElementsByClassName(
    "react-pdf__Page__canvas"
  )[Number(__pageNumber) - 1];
  const deltaTop = 180; //180 + 60;
  const deltaLeft = 180; // 180 + 54;
  const left = signImgXY?.x - deltaLeft;
  const top =
    signImgXY?.y + deltaTop - elementPage?.getBoundingClientRect().top;

  const ratio = 1.3;
  const width = 595 * ratio;
  const height = 842 * ratio;
  return (
    <div
      id="e-contract-doc"
      onScroll={handleScroll}
      style={{ overflow: "auto" }}
    >
      <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
        {Array.apply(null, Array(numPages))
          .map((x, i) => i + 1)
          .map((pageIndex) => (
            <div key={String(pageIndex)} className="border-solid">
              <Page
                className={`page-${pageIndex} drop-target`}
                width={width}
                height={height}
                style={{
                  flexDirection: "row",
                  backgroundColor: "#E4E4E4",
                }}
                onRenderSuccess={onPageRenderSuccess}
                pageIndex={pageIndex - 1}
              >
                <div
                  style={{
                    margin: 0,
                    padding: 0,
                    position: "absolute",
                    top: 0,
                    left: 0,
                    zIndex: 100,
                  }}
                ></div>
                {pageIndex === Number(__pageNumber) && (
                  <div>
                    <div
                      style={{
                        margin: 0,
                        padding: 0,
                        position: "absolute",
                        top,
                        left,
                        zIndex: 100,
                      }}
                      className="signInternal"
                    >
                      {signImg ? (
                        <img
                          className="sigImage"
                          alt="signature"
                          src={signImg}
                        />
                      ) : null}
                    </div>
                  </div>
                )}
              </Page>
            </div>
          ))}
      </Document>
    </div>
  );
}
