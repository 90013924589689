import { Button } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import ListEContract from "./list/index";

const EContract = () => {
  const navigate = useNavigate();

  return (
    <div className="">
      <div className="flex justify-end">
        <Button
          type="primary"
          onClick={() => {
            navigate("/e-contract/upsert/step1");
          }}
        >
          Tạo mới hợp đồng điện tử
        </Button>
      </div>
      <div className="mt-5">
        <ListEContract />
      </div>
    </div>
  );
};

EContract.propTypes = {};

export default EContract;
