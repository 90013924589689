import React from "react";
import Draggable from "react-draggable";

export default class ESignDraggableComponent extends React.Component {
  state = {
    deltaPosition: {
      x: 0,
      y: 0,
    },
    controlledPosition: {
      x: -400,
      y: 200,
    },
  };

  handleDrag = (e, ui) => {
    const { x, y } = this.state.deltaPosition;
    this.setState({
      deltaPosition: {
        x: x + ui.deltaX,
        y: y + ui.deltaY,
      },
    });
  };

  onStart = () => {
    // this.setState({activeDrags: ++this.state.activeDrags});
  };

  onStop = () => {
    // this.setState({activeDrags: --this.state.activeDrags});
    const { index } = this.props;
    const draggableSignAreaElement = document.getElementById(
      `draggable-sign-area-${index}`
    );
    const { top, left, bottom, right } =
      draggableSignAreaElement.getBoundingClientRect();
    const pageCanvasElemements = document.getElementsByClassName(
      "react-pdf__Page__canvas"
    );
    let pageNumber = 0;
    for (let index = 0; index < pageCanvasElemements.length; index++) {
      const pageCanvas = pageCanvasElemements[index].getBoundingClientRect();
      if (
        top <= pageCanvas.bottom &&
        top >= pageCanvas.top &&
        left >= pageCanvas.left &&
        left <= pageCanvas.right &&
        bottom <= pageCanvas.bottom &&
        bottom >= pageCanvas.top &&
        right >= pageCanvas.left &&
        right <= pageCanvas.right
      ) {
        pageNumber = Number(
          pageCanvasElemements[index].parentElement.dataset.pageNumber
        );
      }
    }
    const offsetX =
      left -
      pageCanvasElemements[pageNumber - 1].parentElement.getBoundingClientRect()
        .left;
    const offsetY =
      top -
      pageCanvasElemements[pageNumber - 1].parentElement.getBoundingClientRect()
        .top;

    this.props.setCurrentPosition({ offsetX, offsetY, pageNumber });
  };

  nodeRef = React.createRef();
  render() {
    const dragHandlers = { onStart: this.onStart, onStop: this.onStop };
    const { deltaPosition } = this.state;
    const { email, name, index } = this.props;
    return (
      <Draggable
        nodeRef={this.nodeRef}
        handle="strong"
        {...dragHandlers}
        onDrag={this.handleDrag}
        onStop={this.onStop}
      >
        <div
          id={`draggable-sign-area-${index}`}
          style={{ position: "relative", zIndex: 1000 }}
          ref={this.nodeRef}
          className="box no-cursor"
        >
          <strong className="cursor">
            <span>Chọn vùng ký</span>
            <div className="sigPad">
              <div style={{ textAlign: "center", fontWeight: 400 }}>{name}</div>
            </div>
          </strong>
        </div>
      </Draggable>
    );
  }
}
