import { Button, Form, Input, Space, Spin } from "antd";
import React from "react";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import ButtonsStep3Component from "./buttons-step3.component";
export default function Step3Component({
  data,
  loading,
  form,
  prev,
  onFinish,
}) {
  // if (!data) return <></>;
  return (
    <Spin spinning={loading}>
      <Form onFinish={onFinish} form={form}>
        <div className="bg-white p-4 my-16">
          <Form.List name="signers">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <div key={key} className="flex mb-8 border p-4">
                    <div className="w-11/12 mr-8">
                      <div className="grid grid-cols-2 gap-4">
                        <Form.Item
                          {...restField}
                          name={[name, "name"]}
                          rules={[
                            {
                              required: true,
                              message: "Missing full name",
                            },
                          ]}
                        >
                          <Input placeholder="Họ tên" />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          name={[name, "email"]}
                          rules={[
                            {
                              required: true,
                              message: "Missing email",
                            },
                          ]}
                        >
                          <Input placeholder="Email" />
                        </Form.Item>
                      </div>
                    </div>
                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </div>
                ))}
                <Form.Item>
                  {fields.length < 2 && (
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      Thêm bên ký
                    </Button>
                  )}
                </Form.Item>
              </>
            )}
          </Form.List>
        </div>
        <Form.Item wrapperCol={{ span: 16 }}>
          {ButtonsStep3Component({
            disabled: false,
            prev,
          })}
        </Form.Item>
      </Form>
    </Spin>
  );
}
