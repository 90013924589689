import { EditOutlined } from "@ant-design/icons";
import { useQuery } from "@apollo/client";
import { Spin, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FIND_ALL_SERVICE } from "../../graphql/service-cms";
import TableSort from "../../components/TableSort";

const ListService = () => {
  const [spinning, setSpinning] = useState(false);
  const { data, loading } = useQuery(FIND_ALL_SERVICE);
  const navigate = useNavigate();

  useEffect(() => {
    setSpinning(loading);
  }, [loading]);

  const columns = [
    {
      title: "No.",
      key: "index",
      width: "5%",
      render: (_value, _item, index) => index + 1,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "25%",
      sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: "10%",
      render: (_value, item) => {
        return (
          <>
            <EditOutlined onClick={() => handleUpdate(item)} className="mr10" />
          </>
        );
      },
    },
  ];

  const handleUpdate = (item) => {
    navigate(`/services/edit/${item.id}`);
  };

  return (
    <div>
      <Spin spinning={spinning}>
        <Table dataSource={data?.services || []} columns={columns} />
      </Spin>
    </div>
  );
};

ListService.propTypes = {};

ListService.defaultProps = {};

export default ListService;
