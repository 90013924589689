import gql from "graphql-tag";

export const UPSERT_E_CONTRACT = gql`
  mutation eContract_upsert($eContractInput: EContractInput!) {
    eContract: eContract_upsert(eContractInput: $eContractInput) {
      id
      contents
    }
  }
`;

export const VERIFY_E_CONTRACT_BY_TOKEN = gql`
  query eContract_verifyToken($token: String!) {
    eContract: eContract_verifyToken(token: $token) {
      id
      contents
      inviteEmail
      existedSign
    }
  }
`;

export const SUBMIT_SIGNATURE_E_CONTRACT = gql`
  mutation eContract_submitSignature(
    $submitSignatureEContractInput: SubmitSignatureEContractInput!
  ) {
    eContract: eContract_submitSignature(
      submitSignatureEContractInput: $submitSignatureEContractInput
    ) {
      id
      contents
    }
  }
`;

export const FIND_ALL_E_CONTRACT = gql`
  query eContract_findAll($findAllEContractInput: FindAllEContractInput!) {
    eContracts: eContract_findAll(
      findAllEContractInput: $findAllEContractInput
    ) {
      id
      contents
    }
  }
`;

export const CREATE_UPLOAD_CONTRACT = gql`
  mutation eContract_uploadDoc($file: Upload!) {
    createEContract: eContract_uploadDoc(file: $file) {
      fileName
      url
      mimetype
      encoding
    }
  }
`;

export const REMOVE_E_CONTRACT = gql`
  mutation eContract_removeEContract($contractId: String!) {
    eContract_removeEContract(contractId: $contractId) {
      id
    }
  }
`;

export const FIND_E_CONTRACT_BY_ID = gql`
  query eContract_findOne($contractId: String!) {
    eContract: eContract_findOne(contractId: $contractId) {
      id
      contents
    }
  }
`;
