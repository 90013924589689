import { Alert, Button, Form, Input, Modal, Select } from "antd";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  CLONE_SERVICE_CMS,
  FIND_ALL_SERVICE,
} from "../../../graphql/service-cms";
import React, { useEffect, useState } from "react";

export default function ModalClone({ open, setOpen }) {
  const { data, loading } = useQuery(FIND_ALL_SERVICE);
  const [cloneServiceCMS, { data: dataClone, error, loading: loadingClone }] =
    useMutation(CLONE_SERVICE_CMS);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [value, setValue] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  let message = "";
  const servicesOptions = data?.services?.map((service) => ({
    label: `${service.name}`,
    value: service.id,
  }));

  const handleCancel = () => {
    console.log("Clicked cancel button");
    setOpen(false);
  };
  const onFinish = async (values) => {
    console.log(values);
    const cloneServiceCmInput = {
      id: values.id,
      name: values.name,
      value: values.value,
    };
    await cloneServiceCMS({
      variables: {
        cloneServiceCmInput,
      },
    });
  };

  useEffect(() => {
    setConfirmLoading(loadingClone);
  }, [loadingClone]);

  useEffect(() => {
    setErrorMessage(error?.message);
  }, [error?.message]);
  return (
    <Modal
      title="Title"
      open={open}
      confirmLoading={confirmLoading}
      destroyOnClose
      onCancel={handleCancel}
      onOk={() => setOpen(false)}
    >
      {errorMessage && <Alert message={errorMessage} type="error" />}
      <Form
        name="copy_service"
        onFinish={onFinish}
        autoComplete="off"
        layout="vertical"
      >
        <Form.Item
          rules={[{ required: true, message: "Thiếu" }]}
          label="Dịch vụ được copy:"
          name="value"
        >
          <Select options={servicesOptions} />
        </Form.Item>
        <Form.Item
          name={"name"}
          label="Tên dịch vụ"
          rules={[{ required: true, message: "Thiếu" }]}
        >
          <Input showCount placeholder="Tên dịch vụ" />
        </Form.Item>
        <Form.Item
          name={"id"}
          label="Định danh dịch vụ (Lưu ý: Không thay đổi sau khi tạo)"
          rules={[{ required: true, message: "Thiếu" }]}
        >
          <Input showCount placeholder="Định danh" />
        </Form.Item>
        <Form.Item>
          <div className="flex justify-end">
            <Button type="primary" htmlType="submit">
              Lưu
            </Button>
          </div>
        </Form.Item>
      </Form>
    </Modal>
  );
}
