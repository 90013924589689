import React from 'react';
import ReactQuill from "react-quill";
import PropTypes from "prop-types";
import 'react-quill/dist/quill.snow.css';

/*
 * Quill modules to attach to editor
 * See https://quilljs.com/docs/modules/ for complete options
 */
const modules = {
    toolbar: [
        [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
        [{size: []}],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{'list': 'ordered'}, {'list': 'bullet'},
            {'indent': '-1'}, {'indent': '+1'}],
        ['link', 'image', 'video'],
        ['clean']
    ],
    clipboard: {
        // toggle to add extra line breaks when pasting HTML:
        matchVisual: false,
    }
}
/*
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
const formats = [
    'header', 'font', 'size',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image', 'video'
];

const Editor = ({ value, onChange, placeholder }) => {
    return (
      <>
          <ReactQuill
            theme={"snow"}
            onChange={onChange}
            value={value || ''}
            modules={modules}
            formats={formats}
            bounds={'.app'}
            placeholder={placeholder}
          />
      </>
    )
}



/*
 * PropType validation
 */
Editor.propTypes = {
    placeholder: PropTypes.string,
}

export default Editor
