import React from "react";
import {
  FinishStep2Application,
  SetFormDataStep2Application,
} from "./application/step2.application";
import Step2Component from "./components/step2.component";
import { FetchDataStep1Application } from "../step1/application/step1.application";
export default function EContractUpsertStep2() {
  const { data, loading } = FetchDataStep1Application();
  console.log("data", data);
  const { form } = SetFormDataStep2Application(data?.eContract);
  const { onFinish, prev, uploadFileContactOnDrop } = FinishStep2Application(
    data?.eContract?.id,
    data?.eContract?.contents
  );
  return Step2Component({
    data,
    loading,
    form,
    onFinish,
    prev,
    uploadFileContactOnDrop,
    contractUrl: data?.eContract?.contents?.contractUrl,
  });
}
