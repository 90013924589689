import {
  MinusCircleOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { useLazyQuery, useMutation } from "@apollo/client";
import { Button, Form, Input, message, Select, Spin, Upload } from "antd";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  FIND_ALL_QUIZ,
  FIND_QUIZ_BY_ID,
  UPDATE_QUIZ,
} from "../../graphql/quiz";
import { CREATE_RESOURCE } from "../../graphql/resourceMutations";
import { EnumQuizType, EnumSubMenu } from "../../constants";
import Editor from "../../components/Editor";

const EditSurvey = () => {
  const [spinning, setSpinning] = useState(false);
  const [type, setType] = useState(null);
  const [submenu, setSubMenu] = useState(null);

  const [form] = Form.useForm();
  const params = useParams();
  const [featureImageFileList, setFeatureImageFileList] = useState([{}]);
  const [findQuizById, { data, loading }] = useLazyQuery(FIND_QUIZ_BY_ID);
  const [updateQuiz] = useMutation(UPDATE_QUIZ, {
    refetchQueries: [
      {
        query: FIND_QUIZ_BY_ID,
        variables: { id: params?.surveyId },
      },
      {
        query: FIND_ALL_QUIZ,
        variables: { findAllQuizInput: { skip: 0, take: 999 } },
      },
    ],
  });

  const [uploadImage] = useMutation(CREATE_RESOURCE);

  useEffect(() => {
    if (params?.surveyId) {
      findQuizById({
        variables: {
          id: params?.surveyId,
        },
      });
    }
  }, [params, findQuizById]);

  useEffect(() => {
    if (data?.quiz) {
      form.setFieldsValue({
        description: data?.quiz.description,
        benefit: data?.quiz.benefit,
        descriptionLong: data?.quiz.descriptionLong,
        type: data?.quiz.type,
        name: data?.quiz.name,
        questions: data?.quiz.questions,
        image: data?.quiz.featureImageUrl,
        submenu: data?.quiz.submenu,
      });
      setType(data?.quiz.type);
    }
    setSpinning(false);
  }, [form, data]);
  const onFinish = async (values) => {
    setSpinning(true);

    try {
      const updateQuizInput = {
        quizId: data?.quiz.quizId,
        type: values.type,
        description: values.description,
        benefit: values.benefit,
        descriptionLong: values.descriptionLong,
        name: values.name,
        featureImageUrl: data?.quiz?.featureImageUrl,
        submenu: values.submenu,
      };
      console.log("values", values);
      console.log("updateQuizInput", updateQuizInput);
      if (values.imageFeature) {
        const featureImage = await uploadImage({
          variables: {
            file: values.imageFeature,
          },
        });
        updateQuizInput.featureImageUrl =
          featureImage?.data?.createResource?.url;
      }

      if (
        ![EnumQuizType.API_NLCS, EnumQuizType.API_NLCS_HANH_TRINH].includes(
          values.type
        )
      ) {
        const questionFns = values?.questions?.map((question, index) =>
          convertQuestionObject(question, index)
        );
        updateQuizInput["questions"] = await Promise.all(questionFns);
        await updateQuiz({
          variables: {
            updateQuizInput,
          },
        });
        message.success("Update success!");
        setSpinning(false);
      } else {
        await updateQuiz({
          variables: {
            updateQuizInput,
          },
        });
        message.success("Update success!");
        setSpinning(false);
      }
    } catch (error) {
      message.error(error);
      setSpinning(false);
    }
  };

  const getFile = (e) => {
    return e?.file;
  };

  const convertQuestionObject = async (question, index) => {
    let imageUrl = "";
    if (question.image) {
      if (question.image?.status === "removed") {
        //
      } else if (question.image?.status === "done") {
        imageUrl = question.image.url;
      } else {
        const createResourceDt = await uploadImage({
          variables: {
            file: question.image,
          },
        });
        imageUrl = createResourceDt?.data?.createResource?.url;
      }
    } else {
      imageUrl = question.imageUrl ?? "";
    }

    const answerFns = question.answers.map((answer, answerIndex) =>
      convertAnswerObject(answer, answerIndex)
    );

    const answers = await Promise.all(answerFns);
    return {
      imageUrl,
      questionId: question.questionId,
      question: question.question,
      type: question.type,
      questionIndex: index,
      answers: answers,
    };
  };

  const convertAnswerObject = async (answer, answerIndex) => {
    let imageUrl = "";
    if (answer.image) {
      if (answer.image?.status === "removed") {
        //
      } else if (answer.image?.status === "done") {
        imageUrl = answer.image.url;
      } else {
        const createResourceDt = await uploadImage({
          variables: {
            file: answer.image,
          },
        });
        imageUrl = createResourceDt?.data?.createResource?.url;
      }
    } else {
      imageUrl = answer?.imageUrl ?? "";
    }
    return {
      imageUrl,
      answer: answer.answer,
      answerIndex,
      answerId: answer.answerId,
      value: answer.value,
    };
  };
  const selectTypeAPI = [
    EnumQuizType.API_NLCS,
    EnumQuizType.API_NLCS_HANH_TRINH,
  ].includes(type);
  const propsFeatureImg = {
    onRemove: (file) => {
      const index = featureImageFileList.indexOf(file);
      const newFileList = featureImageFileList.slice();
      newFileList.splice(index, 1);
      setFeatureImageFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFeatureImageFileList([...featureImageFileList, file]);
      return false;
    },
    featureImageFileList,
  };
  return (
    <Spin tip="Loading..." spinning={spinning || loading}>
      <div className="">
        <div>
          <p className="font-semibold	text-2xl">
            Update survey "{data?.quiz.name}"
          </p>
        </div>

        <Form
          name="new_survey"
          form={form}
          onFinish={onFinish}
          autoComplete="off"
          layout="vertical"
        >
          <Form.Item
            name={"name"}
            label="Survey name"
            rules={[{ required: true, message: "Thiếu Tên khảo sát" }]}
          >
            <Input placeholder="Tên khảo sát" />
          </Form.Item>
          <Form.Item
            className="w-4/12 border-0"
            name={"type"}
            rules={[{ required: true, message: "Thiếu Loại khảo sát" }]}
          >
            <Select
              name="select"
              placeholder="Loại khảo sát?"
              onChange={setType}
            >
              <Select.Option value={EnumQuizType.MULTI_GROUPS}>
                Loại nhiều nhóm
              </Select.Option>
              <Select.Option value={EnumQuizType.ONE_GROUP}>
                Loại một nhóm
              </Select.Option>
              <Select.Option value={EnumQuizType.SPECIAL_GROUP}>
                Loại đặc biệt
              </Select.Option>
              <Select.Option value={EnumQuizType.API_NLCS}>
                Năng lượng con số - DEMO
              </Select.Option>
              <Select.Option value={EnumQuizType.API_NLCS_HANH_TRINH}>
                Năng lượng con số - Hành trình của những điểm đến
              </Select.Option>
              <Select.Option
                value={EnumQuizType.API_NLCS_GIAI_MA_VA_THAU_HIEU_BAN_THAN}
              >
                Năng lượng con số - Giải mã và thấu hiểu bản thân
              </Select.Option>
              <Select.Option value={EnumQuizType.API_STVT}>
                Sinh Trắc Vân Tay
              </Select.Option>
              <Select.Option value={EnumQuizType.API_SelfCoach}>
                Khí chất
              </Select.Option>
              <Select.Option value={EnumQuizType.API_SelfCoach_SOTRUONG}>
                Sở trường
              </Select.Option>
              <Select.Option
                value={EnumQuizType.API_SelfCoach_TUDUYHOCTAPTIENGANHHIEUQUA}
              >
                Tư duy học tập tiếng Anh hiệu quả
              </Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            className="w-4/12 border-0"
            name={"submenu"}
            rules={[{ required: true, message: "Thiếu menu" }]}
          >
            <Select
              name="select"
              placeholder="Thuộc menu con"
              onChange={setSubMenu}
            >
              <Select.Option value={EnumSubMenu.TINH_CACH}>
                Tính cách
              </Select.Option>
              <Select.Option value={EnumSubMenu.HOC_TAP}>Học tập</Select.Option>
              <Select.Option value={EnumSubMenu.GIAO_DUC_VA_NUOI_DAY_CON}>
                Giáo dục và nuôi dạy con
              </Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            name={"description"}
            label="Mô tả"
            rules={[{ required: true, message: "Thiếu Mô tả khảo sát" }]}
          >
            <Editor placeholder={"Mô tả khảo sát"} />
          </Form.Item>
          <Form.Item
            name={"benefit"}
            label="Lợi ích"
            rules={[{ required: true, message: "Thiếu Lợi ích" }]}
          >
            <Editor placeholder={"Lợi ích"} />
          </Form.Item>
          <Form.Item
            name={"descriptionLong"}
            label="Mô tả chi tiết"
            rules={[{ required: true, message: "Thiếu Mô tả chi tiết" }]}
          >
            <Editor placeholder={"Mô tả chi tiết"} />
          </Form.Item>

          <Form.Item name="image" getValueFromEvent={getFile}>
            <Upload
              name="feature"
              {...propsFeatureImg}
              listType="picture"
              className="ml-4"
              maxCount={1}
            >
              <Button icon={<UploadOutlined />}>Add image?</Button>
            </Upload>
          </Form.Item>

          {!selectTypeAPI && (
            <>
              <div>
                <p className="text-lg	font-semibold">Question</p>
              </div>
              <Form.List name="questions">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(
                      ({ key, name, ...restField }, questionIndex) => (
                        <div key={key}>
                          <div className="flex w-full">
                            <div className="pr-5 w-full flex">
                              <div className="pr-2">{questionIndex + 1}.</div>
                              <Form.Item
                                {...restField}
                                name={[name, "question"]}
                                className="border-0 w-full"
                                rules={[
                                  {
                                    required: true,
                                    message: "Missing question",
                                  },
                                ]}
                              >
                                <Input
                                  placeholder="Question name?"
                                  className="w-full"
                                />
                              </Form.Item>
                            </div>
                            <Form.Item
                              className="w-3/12 border-0"
                              {...restField}
                              name={[name, "type"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Missing question",
                                },
                              ]}
                            >
                              <Select
                                name="select"
                                placeholder="Type?"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please select question type!",
                                  },
                                ]}
                              >
                                <Select.Option value="single_question">
                                  Single choice
                                </Select.Option>
                              </Select>
                            </Form.Item>
                            <div className="w-1/12 pl-2">
                              <MinusCircleOutlined
                                onClick={() => remove(name)}
                              />
                            </div>
                          </div>
                          <Form.Item
                            {...restField}
                            className="w-10/12 border-0 flex"
                            name={[name, "image"]}
                            getValueFromEvent={getFile}
                          >
                            <Upload
                              name="logo"
                              beforeUpload={(file) => {
                                return false;
                              }}
                              listType="picture"
                              className="ml-4"
                              maxCount={1}
                              defaultFileList={
                                data?.quiz.questions &&
                                data?.quiz.questions[questionIndex]?.imageUrl
                                  ? [
                                      {
                                        status: "done",
                                        url: data?.quiz.questions[questionIndex]
                                          ?.imageUrl,
                                      },
                                    ]
                                  : []
                              }
                            >
                              <Button icon={<UploadOutlined />}>
                                Question({questionIndex + 1}) image?
                              </Button>
                            </Upload>
                          </Form.Item>
                          <Form.List name={[name, "answers"]}>
                            {(
                              fields,
                              { add: addAnswer, remove: removeAnswer }
                            ) => (
                              <>
                                {fields.map(
                                  (
                                    { key, name, ...restField },
                                    answerIndex
                                  ) => {
                                    return (
                                      <div key={key}>
                                        <div className="flex w-full pl-20">
                                          <div className="pr-5 w-full flex">
                                            <div className="pr-2">
                                              {answerIndex + 1}.
                                            </div>
                                            <Form.Item
                                              {...restField}
                                              className="w-10/12 border-0"
                                              name={[name, "answer"]}
                                              rules={[
                                                {
                                                  required: true,
                                                  message: "Missing answer",
                                                },
                                              ]}
                                            >
                                              <Input
                                                placeholder={`Answer ${
                                                  answerIndex + 1
                                                }`}
                                              />
                                            </Form.Item>
                                          </div>
                                          <Form.Item
                                            {...restField}
                                            className="w-3/12 border-0"
                                            name={[name, "value"]}
                                            rules={[
                                              {
                                                required: true,
                                                message: "Missing answer value",
                                              },
                                            ]}
                                          >
                                            <Input
                                              placeholder={`Answer ${
                                                answerIndex + 1
                                              } value`}
                                            />
                                          </Form.Item>
                                          <div className="w-1/12 pl-2">
                                            <MinusCircleOutlined
                                              onClick={() => removeAnswer(name)}
                                            />
                                          </div>
                                        </div>
                                        <div className="flex w-full pl-20 ">
                                          <Form.Item
                                            {...restField}
                                            className="w-10/12 border-0 flex "
                                            name={[name, "image"]}
                                            getValueFromEvent={getFile}
                                          >
                                            <Upload
                                              name="logo"
                                              beforeUpload={(file) => {
                                                return false;
                                              }}
                                              listType="picture"
                                              className="ml-4"
                                              maxCount={1}
                                              defaultFileList={
                                                data?.quiz.questions &&
                                                data?.quiz.questions[
                                                  questionIndex
                                                ]?.answers &&
                                                data?.quiz.questions[
                                                  questionIndex
                                                ]?.answers[answerIndex]
                                                  ?.imageUrl
                                                  ? [
                                                      {
                                                        status: "done",
                                                        url: data?.quiz
                                                          .questions[
                                                          questionIndex
                                                        ]?.answers[answerIndex]
                                                          ?.imageUrl,
                                                      },
                                                    ]
                                                  : []
                                              }
                                            >
                                              <Button icon={<UploadOutlined />}>
                                                Answer image?
                                              </Button>
                                            </Upload>
                                          </Form.Item>
                                        </div>
                                      </div>
                                    );
                                  }
                                )}
                                <Form.Item>
                                  <Button
                                    className="ml-24"
                                    type="dashed"
                                    onClick={() => addAnswer()}
                                    icon={<PlusOutlined />}
                                  >
                                    Add new answer
                                  </Button>
                                </Form.Item>
                              </>
                            )}
                          </Form.List>
                        </div>
                      )
                    )}
                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => add()}
                        block
                        icon={<PlusOutlined />}
                      >
                        Add new question
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </>
          )}
          <Form.Item>
            <div className="flex justify-end">
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </div>
          </Form.Item>
        </Form>
      </div>
    </Spin>
  );
};

EditSurvey.propTypes = {};

EditSurvey.defaultProps = {};

export default EditSurvey;
