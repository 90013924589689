import { Button, Tag } from "antd";
import * as moment from "moment/moment";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import React from "react";
import { getSignOnDate } from "../../../../common/econtract.util";

export function ColumnsListComponentEContract({ handleUpdate, handleDelete }) {
  const columns = [
    {
      title: "",
      key: "index",
      width: "5%",
      render: (_value, _item, index) => index + 1,
    },
    {
      title: "Tên",
      dataIndex: "name",
      key: "name",
      width: "25%",
    },
    {
      title: "Mời ký",
      dataIndex: "counterInvite",
      key: "counterInvite",
      width: "15%",
      render: (value) => {
        if (value) {
          return <Tag color="success">Đã mời {value} lần</Tag>;
        }
        return <Tag color="warning">Chờ</Tag>;
      },
    },
    {
      title: "Ngày ký",
      dataIndex: "signOnDate",
      key: "signOnDate",
      render: (signOnDate) => getSignOnDate(signOnDate),
    },
    {
      title: "Thông tin",
      dataIndex: "info",
      key: "info",
      width: "25%",
      render: (_value, _item, index) => {
        return <div dangerouslySetInnerHTML={{ __html: _value }}></div>;
      },
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      width: "10%",
      render: (_value, item) => {
        console.log("item", item);
        const isDisable = item?.lockForever;
        return (
          <>
            <Button
              icon={<EditOutlined />}
              disabled={isDisable}
              onClick={() => handleUpdate(item)}
              className="mr10"
            />
            <Button
              icon={<DeleteOutlined />}
              disabled={isDisable}
              onClick={() => handleDelete(item)}
              className="mr10"
            />
          </>
        );
      },
    },
  ];
  return { columns };
}
