import {
  MinusCircleOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { useMutation } from "@apollo/client";
import { Button, Form, Input, message, Select, Spin, Upload } from "antd";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { CREATE_QUIZ, FIND_ALL_QUIZ } from "../../graphql/quiz";
import { CREATE_RESOURCE } from "../../graphql/resourceMutations";
import { EnumQuizType } from "../../constants";
import Editor from "../../components/Editor";
import { QUIZ_API_QUESTIONS } from "../../constants/quiz.constant";
import StvtComponent from "./components/stvt.component";
import SelfCoachComponent from "./components/self-coach.component";

const NewSurvey = () => {
  const [spinning, setSpinning] = useState(false);
  const [type, setType] = useState(null);
  const navigate = useNavigate();

  const [uploadImage] = useMutation(CREATE_RESOURCE);
  const [createQuiz] = useMutation(CREATE_QUIZ, {
    refetchQueries: [
      {
        query: FIND_ALL_QUIZ,
        variables: { findAllQuizInput: { skip: 0, take: 999 } },
      },
    ],
  });

  const onFinish = async (values) => {
    setSpinning(true);
    console.log("onFinish");
    try {
      const featureImageUrlFns = convertFeatureObject(values);
      const featureImageUrl = await Promise.all([featureImageUrlFns]);
      const createQuizInput = {
        description: values.description,
        benefit: values.benefit,
        descriptionLong: values.descriptionLong,
        name: values.name,
        type: values.type,
        featureImageUrl: featureImageUrl[0].imageUrl,
      };
      if (
        ![EnumQuizType.API_NLCS, EnumQuizType.API_NLCS_HANH_TRINH].includes(
          values.type
        )
      ) {
        console.log("=== 1");
        console.log(values);
        const questionFns = values?.questions?.map((question, index) =>
          convertQuestionObject(question, index)
        );
        createQuizInput["questions"] = await Promise.all(questionFns);
        await createQuiz({
          variables: {
            createQuizInput,
          },
        });
        setSpinning(false);
        navigate("/surveys");
      } else {
        await createQuiz({
          variables: {
            createQuizInput,
          },
        });
        setSpinning(false);
        navigate("/surveys");
      }
    } catch (error) {
      message.error(error);
      setSpinning(false);
      return;
    }
  };

  const normFile = (e) => {
    return e?.file;
  };
  const convertFeatureObject = async (featureImageUrl) => {
    let imageUrl = "";
    if (featureImageUrl.image) {
      const createFeatureDt = await uploadImage({
        variables: {
          file: featureImageUrl.image,
        },
      });
      imageUrl = createFeatureDt?.data?.createResource?.url;
    }

    return {
      imageUrl,
    };
  };
  const convertQuestionObject = async (question, index) => {
    console.log("convertQuestionObject");
    let imageUrl = "";
    if (question.image) {
      const createResourceDt = await uploadImage({
        variables: {
          file: question.image,
        },
      });

      imageUrl = createResourceDt?.data?.createResource?.url;
    }

    const answerFns = question?.answers?.map((answer, index) =>
      convertAnswerObject(answer, index)
    );
    const answers = await Promise.all(answerFns);

    return {
      imageUrl,
      question: question.question,
      type: question.type,
      answers: answers,
      questionIndex: index,
    };
  };

  const convertAnswerObject = async (answer, index) => {
    let imageUrl = "";
    if (answer.image) {
      if (answer.image?.status === "done" && selectAPI) {
        imageUrl = answer.image.url;
      } else {
        const createResourceDt = await uploadImage({
          variables: {
            file: answer.image,
          },
        });
        imageUrl = createResourceDt?.data?.createResource?.url;
      }
    }
    return {
      imageUrl,
      answer: answer.answer,
      answerIndex: index,
      value: answer.value,
    };
  };
  const selectAPI = [
    EnumQuizType.API_NLCS,
    EnumQuizType.API_NLCS_HANH_TRINH,
    EnumQuizType.API_STVT,
    EnumQuizType.API_SelfCoach,
    EnumQuizType.API_SelfCoach_SOTRUONG,
    EnumQuizType.API_SelfCoach_TUDUYHOCTAPTIENGANHHIEUQUA,
  ].includes(type);

  const selectSTVT = [EnumQuizType.API_STVT].includes(type);

  const selectSelfCoach = [
    EnumQuizType.API_SelfCoach,
    EnumQuizType.API_SelfCoach_SOTRUONG,
    EnumQuizType.API_SelfCoach_TUDUYHOCTAPTIENGANHHIEUQUA,
  ].includes(type);
  return (
    <Spin tip="Loading..." spinning={spinning}>
      <div className="">
        <div>
          <p className="font-semibold	text-2xl">Tạo khảo sát mới</p>
        </div>
        <Form
          name="new_survey"
          onFinish={onFinish}
          autoComplete="off"
          layout="vertical"
        >
          <Form.Item
            name={"name"}
            label="Tên khảo sát"
            rules={[{ required: true, message: "Thiếu Tên khảo sát" }]}
          >
            <Input placeholder="Tên khảo sát" />
          </Form.Item>
          <Form.Item
            className="w-4/12 border-0"
            name={"type"}
            rules={[{ required: true, message: "Thiếu Loại khảo sát" }]}
          >
            <Select
              name="select"
              placeholder="Loại khảo sát?"
              onChange={setType}
            >
              <Select.Option value={EnumQuizType.MULTI_GROUPS}>
                Loại nhiều nhóm
              </Select.Option>
              <Select.Option value={EnumQuizType.ONE_GROUP}>
                Loại một nhóm
              </Select.Option>
              <Select.Option value={EnumQuizType.SPECIAL_GROUP}>
                Loại đặc biệt
              </Select.Option>
              <Select.Option value={EnumQuizType.API_NLCS}>
                Năng lượng con số - DEMO
              </Select.Option>
              <Select.Option value={EnumQuizType.API_NLCS_HANH_TRINH}>
                Năng lượng con số - Hành trình của những điểm đến
              </Select.Option>
              <Select.Option
                value={EnumQuizType.API_NLCS_GIAI_MA_VA_THAU_HIEU_BAN_THAN}
              >
                Năng lượng con số - Giải mã và thấu hiểu bản thân
              </Select.Option>
              <Select.Option value={EnumQuizType.API_STVT}>
                Sinh Trắc Vân Tay
              </Select.Option>
              <Select.Option value={EnumQuizType.API_SelfCoach}>
                Khí chất
              </Select.Option>
              <Select.Option value={EnumQuizType.API_SelfCoach_SOTRUONG}>
                Sở trường
              </Select.Option>
              <Select.Option
                value={EnumQuizType.API_SelfCoach_TUDUYHOCTAPTIENGANHHIEUQUA}
              >
                Tư duy học tập tiếng Anh hiệu quả
              </Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            name={"description"}
            label="Mô tả"
            rules={[{ required: true, message: "Thiếu Mô tả khảo sát" }]}
          >
            <Editor placeholder={"Mô tả khảo sát"} />
          </Form.Item>
          <Form.Item
            name={"benefit"}
            label="Lợi ích"
            rules={[{ required: true, message: "Thiếu Lợi ích" }]}
          >
            <Editor placeholder={"Lợi ích"} />
          </Form.Item>
          <Form.Item
            name={"descriptionLong"}
            label="Mô tả chi tiết"
            rules={[{ required: true, message: "Thiếu Mô tả chi tiết" }]}
          >
            <Editor placeholder={"Mô tả chi tiết"} />
          </Form.Item>
          <Form.Item name="image" getValueFromEvent={normFile}>
            <Upload
              name="logo"
              beforeUpload={(file) => {
                return false;
              }}
              listType="picture"
              className="ml-4"
              maxCount={1}
            >
              <Button icon={<UploadOutlined />}>Add image?</Button>
            </Upload>
          </Form.Item>

          {selectSTVT && <StvtComponent normFile={normFile} />}

          {selectSelfCoach && <SelfCoachComponent normFile={normFile} />}

          {!selectAPI && (
            <>
              <div>
                <p className="text-lg	font-semibold">Câu hỏi</p>
              </div>
              <Form.List name="questions">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(
                      ({ key, name, ...restField }, questionIndex) => (
                        <div key={key}>
                          <div className="flex w-full">
                            <Form.Item
                              className="w-8/12 border-0"
                              {...restField}
                              name={[name, "question"]}
                              rules={[
                                { required: true, message: "Missing question" },
                              ]}
                            >
                              <div className="flex pr-2">
                                <p className="pr-1">{questionIndex + 1}.</p>
                                <Input
                                  placeholder="Question name?"
                                  className="w-full"
                                />
                              </div>
                            </Form.Item>
                            <Form.Item
                              className="w-3/12 border-0"
                              {...restField}
                              name={[name, "type"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Missing type question",
                                },
                              ]}
                            >
                              <Select
                                name="select"
                                placeholder="Type?"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please select question type!",
                                  },
                                ]}
                              >
                                <Select.Option value="single_question">
                                  Single choice
                                </Select.Option>
                              </Select>
                            </Form.Item>
                            <div className="w-1/12 pl-2">
                              <MinusCircleOutlined
                                onClick={() => remove(name)}
                              />
                            </div>
                          </div>
                          <Form.Item
                            {...restField}
                            className="w-10/12 border-0 flex"
                            name={[name, "image"]}
                            getValueFromEvent={normFile}
                          >
                            <Upload
                              name="logo"
                              beforeUpload={(file) => {
                                return false;
                              }}
                              listType="picture"
                              className="ml-4"
                              maxCount={1}
                            >
                              <Button icon={<UploadOutlined />}>
                                Question image?
                              </Button>
                            </Upload>
                          </Form.Item>
                          <Form.List name={[name, "answers"]}>
                            {(
                              fields,
                              { add: addAnswer, remove: removeAnswer }
                            ) => (
                              <>
                                {fields.map(
                                  (
                                    { key, name, ...restField },
                                    answerIndex
                                  ) => {
                                    return (
                                      <div key={key}>
                                        <div className="flex w-full pl-20">
                                          <div className="pl-4">
                                            {answerIndex + 1}.
                                          </div>
                                          <Form.Item
                                            {...restField}
                                            className="w-8/12 border-0 py-2 px-2"
                                            name={[name, "answer"]}
                                            rules={[
                                              {
                                                required: true,
                                                message: "Missing answer",
                                              },
                                            ]}
                                          >
                                            <Input
                                              placeholder={`Answer ${
                                                answerIndex + 1
                                              }`}
                                              className="w-full"
                                            />
                                          </Form.Item>
                                          <div className="w-2"></div>
                                          <Form.Item
                                            {...restField}
                                            className="w-2/12 border-0 px-2"
                                            name={[name, "value"]}
                                            rules={[
                                              {
                                                required: true,
                                                message: "Missing answer value",
                                              },
                                            ]}
                                          >
                                            <Input
                                              placeholder={`Answer ${
                                                answerIndex + 1
                                              } value`}
                                              className="w-full px-2"
                                            />
                                          </Form.Item>
                                          <div className="pl-2 w-1/12">
                                            <MinusCircleOutlined
                                              onClick={() => removeAnswer(name)}
                                            />
                                          </div>
                                        </div>
                                        <div className="flex w-full pl-20 ">
                                          <Form.Item
                                            {...restField}
                                            className="w-10/12 border-0 flex "
                                            name={[name, "image"]}
                                            getValueFromEvent={normFile}
                                            // rules={[{ required: true, message: 'Missing answer' }]}
                                          >
                                            <Upload
                                              name="logo"
                                              beforeUpload={(file) => {
                                                return false;
                                              }}
                                              listType="picture"
                                              className="ml-4"
                                              maxCount={1}
                                            >
                                              <Button icon={<UploadOutlined />}>
                                                Answer image?
                                              </Button>
                                            </Upload>
                                          </Form.Item>
                                        </div>
                                      </div>
                                    );
                                  }
                                )}
                                <Form.Item>
                                  <Button
                                    className="ml-24"
                                    type="dashed"
                                    onClick={() => addAnswer()}
                                    icon={<PlusOutlined />}
                                  >
                                    Add new answer
                                  </Button>
                                </Form.Item>
                              </>
                            )}
                          </Form.List>
                        </div>
                      )
                    )}
                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => add()}
                        block
                        icon={<PlusOutlined />}
                      >
                        Add new question
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </>
          )}

          <Form.Item>
            <div className="flex justify-end">
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </div>
          </Form.Item>
        </Form>
      </div>
    </Spin>
  );
};

NewSurvey.propTypes = {};

NewSurvey.defaultProps = {};

export default NewSurvey;
