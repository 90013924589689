import React, { useState } from "react";
import "./sign-on-contract.css";
import ShowPdfSignOnContract from "./show-pdf-sign-on-contract";
import CanvasSignAreaSignOnContract from "./canvas-sign-area-sign-on-contract";
import DraggableComponent from "../components/draggable.component";

export default function SignOnContract({ file, pageNumber, signers }) {
  const [signImg1, setSignImg1] = useState(null);
  const [signImg2, setSignImg2] = useState(null);
  return (
    <div
      style={{
        position: "absolute",
        top: 20,
        width: "1024px",
        left: "calc(50% - 1024px / 2)",
      }}
    >
      <div style={{ display: "flex", justifyContent: "end" }}>
        <div
          style={{
            margin: 0,
            padding: 0,
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: 100,
          }}
        >
          <CanvasSignAreaSignOnContract setSignImg={setSignImg1} />
        </div>
        <div
          style={{
            margin: 0,
            padding: 0,
            position: "absolute",
            top: 240,
            left: 0,
            zIndex: -100,
          }}
          className="shadowSign"
        >
          {signImg1 ? (
            <img className="sigImage" alt="signature" src={signImg1} />
          ) : null}
        </div>
        {!signImg2 && (
          <div
            style={{
              margin: 0,
              padding: 0,
              position: "absolute",
              top: 240,
              left: 0,
              zIndex: 50,
            }}
          >
            {/*<DraggableComponent*/}
            {/*  signImg={signImg2}*/}
            {/*  setsignImgXY={setsignImgXY}*/}
            {/*/>*/}
          </div>
        )}

        <div
          style={{
            height: "49rem",
            width: "1024px",
            padding: "0px",
            margin: "0px",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <ShowPdfSignOnContract
            file={file}
            signImg1={signImg1}
            setSignImg1={setSignImg1}
            signImg2={signImg2}
            setSignImg2={setSignImg2}
            signers={signers}
            pageNumber={pageNumber}
          />
        </div>
      </div>
    </div>
  );
}
