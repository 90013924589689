import React, { Component } from "react";
import SignatureCanvas from "react-signature-canvas";

import "./sign-canvas-2.css";

export default function SignCanvas2({ setSignImg }) {
  let sigPad = {};

  const clear = () => {
    sigPad.clear();
    setSignImg(null);
  };

  const capture = () => {
    if (window.__pageNumber === -1) {
      alert("Bạn chưa chọn vùng chữ ký ở tài liệu ký");
      return;
    }
    setSignImg(sigPad.getCanvas().toDataURL("image/png"));
  };

  const handleSignatureEnd = () => {
    if (window.__pageNumber === -1) {
      alert("Bạn chưa chọn vùng chữ ký ở tài liệu ký");
      return;
    }
    setSignImg(sigPad.getCanvas().toDataURL("image/png"));
  };

  return (
    <div>
      <div className="sigContainer">
        <SignatureCanvas
          canvasProps={{ className: "sigPad" }}
          onEnd={handleSignatureEnd}
          ref={(ref) => {
            sigPad = ref;
          }}
        />
      </div>
      <div>
        <button className="buttons" onClick={clear}>
          Xóa
        </button>
        <button className="buttons" onClick={capture}>
          Xác nhận
        </button>
      </div>
      {/*{trimmedDataURL ? (*/}
      {/*  <img className="sigImage" alt="signature" src={trimmedDataURL} />*/}
      {/*) : null}*/}
    </div>
  );
}
