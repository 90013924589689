import { PDFDocument } from "pdf-lib";
import { AREA_SIGNATURE } from "../constants/econtract.constant";
import moment from "moment/moment";

async function drawHeadOfSign(pdfDoc, page, xImg, yImg, ratioW, timeStamp) {
  page.drawText(getHeadTextSignature(timeStamp), {
    x: xImg + AREA_SIGNATURE.padding,
    y: yImg + AREA_SIGNATURE.heightContainer - AREA_SIGNATURE.fontSize,
    size: AREA_SIGNATURE.fontSize,
  });
}

async function drawFootOfSign(pdfDoc, page, xImg, yImg, ratioW, hashSignature) {
  page.drawText(getFootTextSignature(hashSignature), {
    x: xImg + AREA_SIGNATURE.padding,
    y: yImg,
    size: AREA_SIGNATURE.fontSize,
  });
}

async function drawPreBorderSign(pdfDoc, page, xImg, yImg, ratioW) {
  const pngUrl = "/line.png";
  const pngImageBytes = await fetch(pngUrl).then((res) => res.arrayBuffer());
  const pngImage = await pdfDoc.embedPng(pngImageBytes);
  const pngDims = pngImage.scale(1);

  page.drawImage(pngImage, {
    x: xImg,
    y: yImg,
    width: AREA_SIGNATURE.padding,
    height:
      AREA_SIGNATURE.heightContainer * ratioW - AREA_SIGNATURE.fontSize / 2,
  });
}

async function embedImages({
  index,
  pdfDoc,
  signImg,
  hashSignature,
  timeStamp,
  offsetX,
  offsetY,
  page,
}) {
  const ratioW = 1;
  // Goc toa do (0, 0) => (0 , page.getHeight() - 180)
  const xImg = offsetX / ratioW;
  const yImg = page.getHeight() - AREA_SIGNATURE.height * ratioW - offsetY;

  // const base64 = document
  //   .querySelector(`#canvas-signature-${index} canvas`)
  //   .toDataURL({
  //     pixelRatio: 2,
  //   });
  // const pngImageBase64_1 = await pdfDoc.embedPng(base64);
  // page.drawImage(pngImageBase64_1, {
  //   x: xImg + AREA_SIGNATURE.padding,
  //   y: yImg + AREA_SIGNATURE.padding,
  //   width: AREA_SIGNATURE.width * ratioW,
  //   height: AREA_SIGNATURE.height * ratioW,
  // });

  console.log(signImg);
  const pngImageBase64 = await pdfDoc.embedPng(signImg);

  page.drawImage(pngImageBase64, {
    x: xImg + AREA_SIGNATURE.padding,
    y: yImg + AREA_SIGNATURE.padding,
    width: AREA_SIGNATURE.width * ratioW,
    height: AREA_SIGNATURE.height * ratioW,
  });
  await drawPreBorderSign(pdfDoc, page, xImg, yImg, ratioW);
  await drawHeadOfSign(pdfDoc, page, xImg, yImg, ratioW, timeStamp);
  await drawFootOfSign(pdfDoc, page, xImg, yImg, ratioW, hashSignature);
}

export default async function RenderPDFEContract(urlPdf, locationOfSignature) {
  const existingPdfBytes = await fetch(urlPdf).then((res) => res.arrayBuffer());
  const pdfDoc = await PDFDocument.load(existingPdfBytes);
  const pages = pdfDoc.getPages();
  const promiseList = locationOfSignature?.map(
    async ({ position, signImg, hashSignature, timeStamp }, index) => {
      const { offsetX, offsetY, pageNumber } = position;
      console.log("locationOfSignature", index);
      return await embedImages({
        index,
        pdfDoc,
        hashSignature,
        timeStamp,
        signImg,
        offsetX,
        offsetY,
        page: pages[pageNumber - 1],
      });
    }
  );
  console.log("promiseList", promiseList);
  await Promise.allSettled(promiseList);
  await pdfDoc.save();
  return await pdfDoc.save();
}
export function getHeadTextSignature(timeStamp) {
  return `Signed ${timeStamp ? moment(timeStamp).format("DD-MM-YYYY") : ""}`;
}

export function getFootTextSignature(hashSignature, width = 160) {
  function truncate(input) {
    if (input.length * 10 > width) {
      return input.substring(0, width / 10) + "...";
    }
    return input;
  }
  return truncate(hashSignature);
}

export function getSignOnDate(signOnDate) {
  return signOnDate
    ? moment(Date.parse(signOnDate)).format("DD/MM/YYYY")
    : "Chưa ký";
}
