import { Route, Routes } from "react-router-dom";
import ProtectedRoute from "../components/ProtectedRoute";
import Dashboard from "../pages/Dashboard";
import Profile from "../pages/Profile";
import Surveys from "../pages/surveys";
import NewSurvey from "../pages/surveys/new";
import EditSurvey from "../pages/surveys/edit";
import Services from "../pages/services";
import NewService from "../pages/services/new";
import EditService from "../pages/services/edit";
import QuizResultMappings from "../pages/quiz-result-mappings";
import EditQuizResultMappings from "../pages/quiz-result-mappings/edit";
import EContract from "../pages/e-contract";
import EContractSign from "../pages/e-contract-sign";
import Referral from "../pages/referral";
import Forbidden from "../pages/Forbidden";
import Login from "../pages/Login";
import Page404 from "../pages/404";
import React from "react";
import EContractUpsertStep1 from "../pages/e-contract/upsert/step1/e-contract.upsert.step1";
import EContractUpsertStep2 from "../pages/e-contract/upsert/step2/e-contract.upsert.step2";
import EContractUpsertStep3 from "../pages/e-contract/upsert/step3/e-contract.upsert.step3";
import EContractUpsertStep4 from "../pages/e-contract/upsert/step4/e-contract.upsert.step4";
import SignCanvas1 from "../pages/sign-canvas-1";
import SignCanvas2 from "../pages/sign-canvas-2";
import TestReactDraggable from "../pages/test-react-draggable";
import TestShowPDF from "../pages/test-show-pdf";
import TestIFrame from "../pages/test-iframe";
import EContractUpsertStep5 from "../pages/e-contract/upsert/step5/e-contract.upsert.step5";
import EContractUpsertStep6 from "../pages/e-contract/upsert/step6/e-contract.upsert.step6";
import EContractUpsertStep7 from "../pages/e-contract/upsert/step7/e-contract.upsert.step7";
export default function RoutesApp() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <ProtectedRoute>
            <Dashboard />
          </ProtectedRoute>
        }
      />
      <Route
        path="/dashboard"
        element={
          <ProtectedRoute>
            <Dashboard />
          </ProtectedRoute>
        }
      />
      <Route
        path="/profile"
        element={
          <ProtectedRoute>
            <Profile />
          </ProtectedRoute>
        }
      />
      <Route
        path="/surveys"
        element={
          <ProtectedRoute>
            <Surveys />
          </ProtectedRoute>
        }
      />
      <Route
        path="/surveys/create"
        element={
          <ProtectedRoute>
            <NewSurvey />
          </ProtectedRoute>
        }
      />
      <Route
        path="/surveys/edit/:surveyId"
        element={
          <ProtectedRoute>
            <EditSurvey />
          </ProtectedRoute>
        }
      />
      <Route
        path="/services"
        element={
          <ProtectedRoute>
            <Services />
          </ProtectedRoute>
        }
      />
      <Route
        path="/services/create"
        element={
          <ProtectedRoute>
            <NewService />
          </ProtectedRoute>
        }
      />
      <Route
        path="/services/edit/:serviceId"
        element={
          <ProtectedRoute>
            <EditService />
          </ProtectedRoute>
        }
      />
      <Route
        path="/quiz-result-mappings"
        element={
          <ProtectedRoute>
            <QuizResultMappings />
          </ProtectedRoute>
        }
      />
      <Route
        path="/quiz-result-mappings/edit/:quizMappingId"
        element={
          <ProtectedRoute>
            <EditQuizResultMappings />
          </ProtectedRoute>
        }
      />
      <Route
        path="/e-contract"
        element={
          <ProtectedRoute>
            <EContract />
          </ProtectedRoute>
        }
      />
      <Route path="/e-contract-sign/:contractId" element={<EContractSign />} />
      <Route path="/sign-canvas-1/:id" element={<SignCanvas1 />} />
      <Route path="/sign-canvas-2/:id" element={<SignCanvas2 />} />
      <Route path="/test-react-draggable" element={<TestReactDraggable />} />
      <Route path="/test-show-pdf" element={<TestShowPDF />} />
      <Route path="/test-iframe" element={<TestIFrame />} />

      <Route
        path="/e-contract/upsert/step1"
        element={
          <ProtectedRoute>
            <EContractUpsertStep1 />
          </ProtectedRoute>
        }
      />
      <Route
        path="/e-contract/upsert/step2"
        element={
          <ProtectedRoute>
            <EContractUpsertStep2 />
          </ProtectedRoute>
        }
      />
      <Route
        path="/e-contract/upsert/step3"
        element={
          <ProtectedRoute>
            <EContractUpsertStep3 />
          </ProtectedRoute>
        }
      />
      <Route
        path="/e-contract/upsert/step4"
        element={
          <ProtectedRoute>
            <EContractUpsertStep4 />
          </ProtectedRoute>
        }
      />
      <Route
        path="/e-contract/upsert/step5"
        element={
          <ProtectedRoute>
            <EContractUpsertStep5 />
          </ProtectedRoute>
        }
      />
      <Route
        path="/e-contract/upsert/step6"
        element={<EContractUpsertStep6 />}
      />

      <Route
        path="/e-contract/upsert/step7"
        element={<EContractUpsertStep7 />}
      />

      <Route
        path="/referral"
        element={
          <ProtectedRoute>
            <Referral />
          </ProtectedRoute>
        }
      />
      <Route path="/Forbidden" element={<Forbidden />} />
      <Route path="/login" element={<Login />} />
      <Route path="/:404" element={<Page404 />} />
    </Routes>
  );
}
