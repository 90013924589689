import { Button, Form, Input, Space, Spin } from "antd";
import React, { useState } from "react";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import ESignDraggableComponent from "./e-sign-draggable.component";
import ShowPdfOriginComponent from "./show-pdf-origin/show-pdf-origin.component";
import ButtonsStep2Component from "../../step2/components/buttons-step2.component";
export default function Step4Component({
  data,
  setCurrentPosition,
  setCurrentPosition2,
  contents,
  loading,
  form,
  prev,
  onFinish,
}) {
  // if (!data) return <></>;
  const signers = contents?.signers || [];
  const file = contents?.contractUrl;
  return (
    <Spin spinning={false}>
      <div>
        <div
          style={{
            position: "absolute",
            top: 20,
            width: "1024px",
            left: "calc(50% - 1024px / 2)",
          }}
        >
          <div style={{ display: "flex", justifyContent: "end" }}>
            <div
              style={{
                height: "49rem",
                width: "1024px",
                padding: "0px",
                margin: "0px",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <div>
                <div
                  style={{
                    margin: 0,
                    padding: 0,
                    position: "absolute",
                    top: 0,
                    left: 0,
                    zIndex: 100,
                  }}
                >
                  <ESignDraggableComponent
                    index={0}
                    setCurrentPosition={setCurrentPosition}
                    email={signers[0]?.email}
                    name={signers[0]?.name}
                  />
                </div>
                <div
                  style={{
                    margin: 0,
                    padding: 0,
                    position: "absolute",
                    top: 240,
                    left: 0,
                    zIndex: 50,
                  }}
                >
                  <ESignDraggableComponent
                    index={1}
                    setCurrentPosition={setCurrentPosition2}
                    email={signers[1]?.email}
                    name={signers[1]?.name}
                  />
                </div>
              </div>
              <ShowPdfOriginComponent file={file} />
            </div>
          </div>
        </div>
        <div
          style={{
            position: "absolute",
            top: 600,
            width: "1024px",
            left: "calc(50% - 1024px / 2)",
          }}
        >
          {ButtonsStep2Component({
            disabled: false,
            prev,
            onFinish,
          })}
        </div>
      </div>
    </Spin>
  );
}
