import React, {Component, useState} from 'react'
import SignatureCanvas from 'react-signature-canvas'

import './canvas-sign-area-sign-on-contract.css'

export default function CanvasSignAreaSignOnContract ({ signImg, setSignImg }) {
  let sigPad = {}
  const [confirm, setConfirm] = useState(false)
  const clear = () => {
    sigPad.clear()
    setConfirm(false)
    setSignImg(null)
  }

  const capture = () => {
    if (window.__pageNumber === -1) {
      alert('Bạn chưa chọn vùng chữ ký ở tài liệu ký')
      return;
    }
    setConfirm(true)
    // setSignImg(sigPad.getCanvas().toDataURL('image/png'))
  }

  const handleSignatureEnd = () => {
    if (window.__pageNumber === -1) {
      alert('Bạn chưa chọn vùng chữ ký ở tài liệu ký')
      return;
    }
    setSignImg(sigPad.getCanvas().toDataURL('image/png'))
  }
  if (confirm) {
    return (
      <div>
        <img className="sigImage" alt='signature'
             src={ signImg} />
      </div>
    )
  }
  return <div>
    <div className="sigContainer">
      <SignatureCanvas canvasProps={{ className: "sigPad" }}
                           onEnd={handleSignatureEnd}
                           ref={(ref) => { sigPad = ref }} />
    </div>
    <div>
      <button className="buttons" onClick={clear}>
        Xóa
      </button>
      <button className="buttons" onClick={capture}>
        Xác nhận
      </button>
    </div>

  </div>

}
