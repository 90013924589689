export const ColorList = ["#f56a00", "#7265e6", "#ffbf00", "#00a2ae"];
export const UserRoleConstant = {
  ADMIN: "admin",
  TEACHER: "teacher",
  STUDENT: "student",
};

export const HOME_ROUTE = "/student";

export let GLOBAL_VISIBLE = false;

export const EnumQuizType = {
  ONE_GROUP: "ONE_GROUP",
  MULTI_GROUPS: "MULTI_GROUPS",
  SPECIAL_GROUP: "SPECIAL_GROUP",
  API_NLCS: "API_NLCS",
  API_NLCS_HANH_TRINH: "API_NLCS_HANH_TRINH",
  API_NLCS_GIAI_MA_VA_THAU_HIEU_BAN_THAN:
    "API_NLCS_GIAI_MA_VA_THAU_HIEU_BAN_THAN",
  API_STVT: "API_STVT",
  API_SelfCoach: "API_SelfCoach",
  API_SelfCoach_TUDUYHOCTAPTIENGANHHIEUQUA:
    "API_SelfCoach_TUDUYHOCTAPTIENGANHHIEUQUA",
  API_SelfCoach_SOTRUONG: "API_SelfCoach_SOTRUONG",
};

export const EnumSubMenu = {
  TINH_CACH: "TINH_CACH",
  HOC_TAP: "HOC_TAP",
  GIAO_DUC_VA_NUOI_DAY_CON: "GIAO_DUC_VA_NUOI_DAY_CON",
};
