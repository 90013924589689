import { Button } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import ListSurvey from './list';

const Surveys = () => {
  const navigate = useNavigate()

  return <div className=''>
    <div className='flex justify-end'>
      <Button 
        type='primary'
        onClick={() => {
          navigate('/surveys/create');
        }}
      >
        Create new survey
      </Button>
    </div>
    <div className='mt-5'>
      <ListSurvey />
    </div>
  </div>;
};

Surveys.propTypes = {};

export default Surveys;
