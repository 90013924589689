import { useLocation } from "react-router-dom";
import { useEffect, useMemo } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  CREATE_UPLOAD_CONTRACT,
  FIND_E_CONTRACT_BY_ID,
  UPSERT_E_CONTRACT,
} from "../../../../../graphql/e-contract";
import { QUERY_ID } from "../../../../../constants/econtract.constant";
import { Form, message } from "antd";
import * as moment from "moment/moment";
import { useNavigate } from "react-router-dom";

export function SetFormDataStep2Application(eContract) {
  const [form] = Form.useForm();
  useEffect(() => {
    if (eContract) {
      const { contents } = eContract;
      console.log("contents 222", contents);
      form.setFieldsValue({
        image: contents?.contractUrl,
      });
    }
  }, [eContract]);
  return {
    form,
  };
}

export function FinishStep2Application(contractId, contents) {
  const navigate = useNavigate();
  const [upsertEContract] = useMutation(UPSERT_E_CONTRACT, {
    refetchQueries: [
      // {
      //   query: FIND_E_CONTRACT_BY_ID,
      //   variables: { contractId: params?.contractId },
      // },
    ],
  });
  const [uploadFileContract, { loading: loadingUpload }] = useMutation(
    CREATE_UPLOAD_CONTRACT
  );

  const uploadFileContactOnDrop = async (file) => {
    try {
      const createContractDt = await uploadFileContract({
        variables: {
          file,
        },
      });
      const contractUrl = createContractDt?.data?.createEContract?.url;
      console.log("contractUrl", contractUrl);
      await upsertEContract({
        variables: {
          eContractInput: {
            id: contractId,
            contents: {
              contractUrl,
            },
          },
        },
      });
      message.success("Update success!");
    } catch (error) {
      message.error(error);
    }
  };

  const onFinish = () => {
    navigate(`/e-contract/upsert/step3?id=${contractId}`);
  };
  const prev = () => {
    navigate(`/e-contract/upsert/step1?id=${contractId}`);
  };
  return { onFinish, prev, uploadFileContactOnDrop };
}
