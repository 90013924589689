import React from "react";
import {
  FetchDataStep1Application,
  FinishStep1Application,
  SetFormDataStep1Application,
} from "./application/step1.application";
import Step1Component from "./components/step1.component";
export default function EContractUpsertStep1() {
  const { data, loading } = FetchDataStep1Application();
  console.log("data", data);
  const { form } = SetFormDataStep1Application(data?.eContract);
  const { onFinish } = FinishStep1Application(data?.eContract?.id);
  console.log("EContractUpsertStep1");
  return Step1Component({ data, loading, form, onFinish });
}
