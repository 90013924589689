import { useMutation, useQuery } from "@apollo/client";
import {
  FIND_ALL_E_CONTRACT,
  REMOVE_E_CONTRACT,
} from "../../../../graphql/e-contract";
import { Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import React from "react";
import { useNavigate } from "react-router-dom";

export function FetchDataEContractListApplication() {
  const { data, loading } = useQuery(FIND_ALL_E_CONTRACT, {
    variables: { findAllEContractInput: { skip: 0, take: 999 } },
  });
  return { data, loading };
}

export function ParseDataToTableEContractListApplication(array) {
  if (!array) return [];
  const eContracts = array.map(({ id, contents }) => {
    const {
      info,
      name,
      counterInvite,
      numberDocument,
      signOnDate,
      lockForever,
    } = contents;
    return {
      id,
      info,
      counterInvite,
      name,
      numberDocument,
      signOnDate,
      lockForever,
    };
  });
  return { eContracts };
}

export function HandleCRUDTableEContractListApplication() {
  const navigate = useNavigate();

  const [removeEContract] = useMutation(REMOVE_E_CONTRACT, {
    refetchQueries: [
      {
        query: FIND_ALL_E_CONTRACT,
        variables: { findAllEContractInput: { skip: 0, take: 999 } },
      },
    ],
    onCompleted: () => {},
  });
  const handleUpdate = (item) => {
    navigate(`/e-contract/upsert/step1?id=${item.id}`);
  };
  const handleDelete = (item) => {
    Modal.confirm({
      title: `Delete survey`,
      icon: <ExclamationCircleOutlined />,
      content: `Are you sure delete this survey "${item.name}"?`,
      okText: "Delete",
      cancelText: "Cancel",
      onOk: () => {
        removeEContract({
          variables: {
            contractId: item.id,
          },
        });
      },
    });
  };
  return { handleUpdate, handleDelete };
}
