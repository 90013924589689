import React, { useState, useContext, useEffect } from "react";
import { Layout, Menu, Avatar } from "antd";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { UserOutlined } from "@ant-design/icons";

import logo from "../images/logo.png";
import { AuthContext } from "../contexts/auth";

const { Content, Sider } = Layout;

const items = [
  {
    label: "Trang chính",
    key: "dashboard",
  },
  {
    label: "Chương trình giới thiệu",
    key: "referral",
  },
  {
    label: "Các bài quiz",
    key: "surveys",
  },
  {
    label: "Thêm kết quả cho bài quiz",
    key: "quiz-result-mappings",
  },
  {
    label: "Dịch vụ CMS",
    key: "services",
  },
  {
    label: "Chữ ký điện tử",
    key: "e-contract",
  },
  {
    label: "Thoát",
    key: "logout",
  },
];

const LayoutPage = ({ children }) => {
  const [collapsed, setCollapsed] = useState(false);
  const [selectedKey, setSelectedKey] = useState("");
  const { user, authDispatch } = useContext(AuthContext);

  const navigate = useNavigate();

  useEffect(() => {
    const itemSelected = items.find((item) =>
      window.location.href.includes(item.key)
    );

    if (itemSelected) {
      setSelectedKey(itemSelected.key);
    }
  }, []);

  const onCollapse = (isCollapsed) => {
    setCollapsed(isCollapsed);
  };

  const handleClick = (element) => {
    if (element.key === "logout") {
      authDispatch({
        type: "logout",
      });
      navigate(`/login`);
      return;
    }
    navigate(`/${element.key}`);
    setSelectedKey(element.key);
  };

  const handleClickProfile = () => {
    navigate("/profile");
  };

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider
        className={"sider"}
        collapsible
        collapsed={collapsed}
        onCollapse={onCollapse}
      >
        <div>
          <div className="sider__logo flex items-center justify-center">
            <img src={logo} width={100} alt="logo"></img>
          </div>
          <Menu
            className={"sider__menu"}
            items={items}
            defaultSelectedKeys={["dashboard"]}
            mode="inline"
            theme="dark"
            onClick={handleClick}
            selectedKeys={selectedKey ? [selectedKey] : ["dashboard"]}
          ></Menu>
        </div>
        <div className="sider__user">
          <div className="sider__user__content" onClick={handleClickProfile}>
            {user?.avatar ? (
              <Avatar size={"medium"} src={user.avatar} />
            ) : (
              <Avatar size={"medium"} icon={<UserOutlined />} />
            )}
            <div className="sider__user__content__info">
              <span>{user?.fullname}</span>
            </div>
          </div>
        </div>
      </Sider>
      <Layout>
        <Content className="site-layout m-4">{children}</Content>
      </Layout>
    </Layout>
  );
};

LayoutPage.propTypes = {
  children: PropTypes.node,
  selectedKey: PropTypes.string,
};

export default LayoutPage;
