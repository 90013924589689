import { useContext } from 'react';
import {
  Navigate,
} from 'react-router-dom';
import { AuthContext } from '../contexts/auth';
import Layout from './LayoutPage';

const ProtectedRoute = ({ children }) => {
  const auth = useContext(AuthContext);

  const { token, user } = auth;
  if (!token) {
    return <Navigate to="/login" replace />;
  }

  if (user?.role !== 'admin') {
    return <Navigate to="/Forbidden" replace />;
  }

  return  <Layout>
      {children}
    </Layout>
};

export default ProtectedRoute;