import React, { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { FIND_E_CONTRACT_BY_ID } from "../../graphql/e-contract";
import { pdfjs } from "react-pdf";
import { useParams } from "react-router-dom";
import SignCanvas2 from "../sign-canvas-2";
import DraggableComponent from "../test-iframe/draggable.component";
import TestShowPdf from "../test-show-pdf";
import SignOnContract from "../../components/sign-on-contract";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const EContractSign = () => {
  const params = useParams();

  const [findEContractById, { data, loading }] = useLazyQuery(
    FIND_E_CONTRACT_BY_ID
  );
  console.log({ params });

  useEffect(() => {
    if (params?.contractId) {
      findEContractById({
        variables: {
          contractId: params?.contractId,
        },
      });
    }
  }, [params, findEContractById]);

  // useEffect(() => {
  //   if (data?.quiz) {
  //     form.setFieldsValue({
  //       description: data?.quiz.description,
  //       type: data?.quiz.type,
  //       name: data?.quiz.name,
  //       questions: data?.quiz.questions,
  //       image: data?.quiz?.featureImageUrl,
  //       logo: data?.quiz?.featureImageUrl
  //     });
  //     setType(data?.quiz.type)
  //   }
  //   setSpinning(false);
  // }, [form, data]);

  // const [currentPosition, setCurrentPosition] = useState({
  //   xRate: 0,
  //   yRate: 0
  // });

  // const [currentPosition2, setCurrentPosition2] = useState({
  //   xRate: 0,
  //   yRate: 0
  // });
  // const onDrag = (e, data) => {
  //   setCurrentPosition({ xRate: data.lastX, yRate: data.lastY });
  // };

  // const onDrag2 = (e, data) => {
  //   setCurrentPosition2({ xRate: data.lastX, yRate: data.lastY });
  // };
  const onFinish = async (values) => {};
  const contractUrl = data?.eContract?.contents?.contractUrl;
  const signers = data?.eContract?.contents?.signers;

  console.log(signers, contractUrl);
  // const signers = data?.eContract?.signers || [];
  // const pageNumber =
  //   0 || signers[0]?.locationOfSignature?.position?.pageNumber || 0;

  return (
    <SignOnContract file={contractUrl} pageNumber={0} signers={signers || []} />
  );
};

export default EContractSign;
