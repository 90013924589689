import React from "react";
import {
  FetchDataStep6Application,
  FinishStep6Application,
} from "./application/step6.application";
import Step6Component from "./components/step6.component";
export default function EContractUpsertStep6() {
  const { data, loading } = FetchDataStep6Application();
  console.log("data", data);
  const eContract = data?.eContract;
  const { onFinish, setReload } = FinishStep6Application(
    eContract?.id,
    eContract?.contents
  );
  // return Step6Component({
  //   existedSign: eContract?.existedSign,
  //   contents: eContract?.contents,
  //   inviteEmail: eContract?.inviteEmail,
  //   LOCKED: eContract?.contents?.LOCKED,
  //   onFinish,
  // });
  return (
    <>
      <Step6Component
        existedSign={eContract?.existedSign}
        contents={eContract?.contents}
        inviteEmail={eContract?.inviteEmail}
        LOCKED={eContract?.contents?.LOCKED}
        onFinish={onFinish}
      />
    </>
  );
}
