import { Button, Modal } from "antd";
export default function ModalComponent({
  title,
  children,
  open,
  handleCancel,
  handleOk,
  footer,
}) {
  return (
    <>
      <Modal
        title={title}
        open={open}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={footer}
      >
        {children}
      </Modal>
    </>
  );
}
