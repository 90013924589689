import gql from "graphql-tag";

export const CREATE_QUIZ = gql`
  mutation createQuiz($createQuizInput: CreateQuizInput!) {
    quiz_createQuiz(createQuizInput: $createQuizInput) {
      description
      benefit
      descriptionLong
      name
      featureImageUrl
      questions {
        imageUrl
        question
        questionId
        answers {
          answer
          answerId
          value
          imageUrl
        }
      }
    }
  }
`;

export const FIND_ALL_QUIZ = gql`
  query quiz_findAll($findAllQuizInput: FindAllQuizInput!) {
    quizs: quiz_findAll(findAllQuizInput: $findAllQuizInput) {
      quizId
      description
      created_at
      name
      isPublic
    }
  }
`;

export const REMOVE_QUIZ = gql`
  mutation quiz_removeQuiz($id: String!) {
    quiz_removeQuiz(id: $id) {
      name
    }
  }
`;

export const UPDATE_QUIZ = gql`
  mutation quiz_updateQuiz($updateQuizInput: UpdateQuizInput!) {
    quiz: quiz_updateQuiz(updateQuizInput: $updateQuizInput) {
      quizId
      description
      benefit
      descriptionLong
      name
      submenu
      isPublic
      questions {
        imageUrl
        question
        type
        questionIndex
        questionId
        answers {
          answer
          answerId
          answerIndex
          value
          imageUrl
        }
      }
    }
  }
`;

export const FIND_QUIZ_BY_ID = gql`
  query quiz_findOne($id: String!) {
    quiz: quiz_findOne(id: $id) {
      quizId
      description
      benefit
      descriptionLong
      name
      isPublic
      type
      submenu
      featureImageUrl
      questions {
        imageUrl
        question
        type
        questionIndex
        questionId
        answers {
          answer
          answerId
          value
          answerIndex
          imageUrl
        }
      }
    }
  }
`;
