import React, { useContext, useRef } from 'react';

import { Avatar, Button } from 'antd';
import {
  UserOutlined,
} from '@ant-design/icons';

import { AuthContext } from '../contexts/auth';

const Profile = () => {
  const auth = useContext(AuthContext);
  const avatarRef = useRef(null);

  const changeAvatar = () => {
    avatarRef.current.click();
  };
 
  const handleChangeImage = event => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];
      let formData = new FormData();
      formData.append('file', img);
    }
  };

  return <div className='profile'>
    <div className='center_horizontal vertical'>
      <div className='change_avatar'>
        {
          auth.user.avatar
            ?
            <Avatar size={100} className={'avatar'} src={auth.user.avatar} />
            :
            <Avatar size={100} className={'avatar'} icon={<UserOutlined />}/>
        }
        <Button onClick={changeAvatar}>Change Avatar</Button>
        <input type="file"
          ref={avatarRef}
          id="cover" name="cover"
          accept="image/png, image/jpeg"
          style={{display: 'none'}}
          onChange={handleChangeImage}></input>
      </div>

      <div className='info_line'>
        <div className='title'>Full name:</div>
        <div className='value'>{auth.user.fullname}</div>
      </div>

      <div className='info_line'>
        <div className='title'>Email:</div>
        <div className='value'>{auth.user.email}</div>
      </div>
      <div className='change_password'>
        <Button>Change password</Button>
      </div>
    </div>
  </div>;
};

Profile.propTypes = {};

export default Profile;
