export const QUIZ_API_QUESTIONS = {
  Question: {
    Left: 'BÀN TAY TRÁI',
    Right: 'BÀN TAY PHẢI'
  },
  Answer4: {
    Left: {
      'Whorl':  {
        answerValue: '2',
        imageURL: 'https://api.gentalents.com/ST/whorl_2.png'
      },
      'Loop':  {
        answerValue: '1',
        imageURL: 'https://api.gentalents.com/GEN_4/LEFT/LL_1.png'
      },
      'Radial':  {
        answerValue: '4',
        imageURL: 'https://api.gentalents.com/GEN_4/LEFT/RLL_4.png'
      },
      'Arch':  {
        answerValue: '3',
        imageURL: 'https://api.gentalents.com/ST/arch_2.png'
      },
    },
    Right: {
      'Whorl':  {
        answerValue: '2',
        imageURL: 'https://api.gentalents.com/ST/whorl_2.png'
      },
      'Loop':  {
        answerValue: '1',
        imageURL: 'https://api.gentalents.com/GEN_4/RIGHT/LR_1.png'
      },
      'Radial':  {
        answerValue: '4',
        imageURL: 'https://api.gentalents.com/GEN_4/RIGHT/RLR_4.png'
      },
      'Arch':  {
        answerValue: '3',
        imageURL: 'https://api.gentalents.com/ST/arch_2.png'
      },
    }
  },
  Answer6: {
    Left: {
      'Whorl':  {
        answerValue: '2',
        imageURL: 'https://api.gentalents.com/GEN_6/LEFT/WL_2.png'
      },
      'Loop':  {
        answerValue: '1',
        imageURL: 'https://api.gentalents.com/GEN_6/LEFT/LL_1.png'
      },
      'Radial':  {
        answerValue: '6',
        imageURL: 'https://api.gentalents.com/GEN_6/LEFT/RLL_6.png'
      },
      'Arch':  {
        answerValue: '3',
        imageURL: 'https://api.gentalents.com/GEN_6/LEFT/AL_3.png'
      },
      'Tented Arch':  {
        answerValue: '4',
        imageURL: 'https://api.gentalents.com/GEN_6/LEFT/TAL_4.png'
      },
      'Composite':  {
        answerValue: '5',
        imageURL: 'https://api.gentalents.com/GEN_6/LEFT/CL_5.png'
      },
    },
    Right: {
      'Whorl':  {
        answerValue: '2',
        imageURL: 'https://api.gentalents.com/GEN_6/RIGHT/WR_2.png'
      },
      'Loop':  {
        answerValue: '1',
        imageURL: 'https://api.gentalents.com/GEN_6/RIGHT/LR_1.png'
      },
      'Radial':  {
        answerValue: '6',
        imageURL: 'https://api.gentalents.com/GEN_6/RIGHT/RLR_6.png'
      },
      'Arch':  {
        answerValue: '3',
        imageURL: 'https://api.gentalents.com/GEN_6/RIGHT/AR_3.png'
      },
      'Tented Arch':  {
        answerValue: '4',
        imageURL: 'https://api.gentalents.com/GEN_6/RIGHT/TAR_4.png'
      },
      'Composite':  {
        answerValue: '5',
        imageURL: 'https://api.gentalents.com/GEN_6/RIGHT/CR_5.png'
      },
    }
  },
  Left: {
    ThumbLeft: 'Nhóm chủng vân tay của ngón CÁI trái?',
    IndexLeft: 'Nhóm chủng vân tay của ngón TRỎ trái?',
    MiddleLeft: 'Nhóm chủng vân tay của ngón GIỮA trái?',
    RingLeft: 'Nhóm chủng vân tay của ngón ÁP ÚT trái?',
    LittleLeft: 'Nhóm chủng vân tay của ngón ÚT trái?',
  },
  Right: {
    ThumbRight: 'Nhóm chủng vân tay của ngón CÁI phải?',
    IndexRight: 'Nhóm chủng vân tay của ngón TRỎ phải?',
    MiddleRight: 'Nhóm chủng vân tay của ngón GIỮA phải?',
    RingRight: 'Nhóm chủng vân tay của ngón ÁP ÚT phải?',
    LittleRight: 'Nhóm chủng vân tay của ngón ÚT phải?',
  },
};
