import React, { Suspense } from "react";
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
import { createUploadLink } from "apollo-upload-client";

import { BrowserRouter } from "react-router-dom";
import { setContext } from "@apollo/client/link/context";

import "./styles/main.scss";
import "./App.css";

import Loading from "./components/Loading";

import AuthProvider from "./contexts/auth";
import RoutesApp from "./config/routes.app";

const httpLink = createUploadLink({
  uri: process.env.REACT_APP_API_HOST,
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem("token");
  const query = new URLSearchParams(window.location.search);
  // return the headers to the context so httpLink can read them
  const bearerToken = `Bearer ${query.get("token") || token}`;
  return {
    headers: {
      ...headers,
      authorization: bearerToken || "",
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

const App = () => {
  return (
    <ApolloProvider client={client}>
      <AuthProvider>
        <BrowserRouter>
          <Suspense fallback={<Loading />}>
            <RoutesApp />
          </Suspense>
        </BrowserRouter>
      </AuthProvider>
    </ApolloProvider>
  );
};

export default App;
