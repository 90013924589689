import React from "react";
import { FinishStep5Application } from "./application/step5.application";
import Step5Component from "./components/step5.component";
import { FetchDataStep1Application } from "../step1/application/step1.application";
export default function EContractUpsertStep5() {
  const { data, loading } = FetchDataStep1Application();
  const { onFinish, prev } = FinishStep5Application(
    data?.eContract?.id,
    data?.eContract?.contents
  );
  return Step5Component({
    data,
    loading,
    onFinish,
    prev,
    contents: data?.eContract?.contents,
  });
}
