import React, { useState } from "react";
import { Document, Page } from "react-pdf";

import {
  AREA_SIGNATURE,
  PAGE_PDF_SIZE,
} from "../../../../../constants/econtract.constant";
import CanvasSignatureComponent from "../../step6/components/canvas-signature.component";
export default function PdfComponent({ file, locationOfSignature }) {
  const [numPages, setNumPages] = useState(null);

  async function onDocumentLoadSuccess(successEvent) {
    const { numPages } = successEvent;
    setNumPages(numPages);
  }

  function onPageRenderSuccess(props) {}
  function handleScroll(e) {}

  // const top = offsetY;
  // const left = offsetX;
  // A4
  // const ratio = 1;
  // const width = 595.28 * ratio;
  // const height = 841.89 * ratio;

  // Letter
  const { width, height } = PAGE_PDF_SIZE;

  function renderSignature(pageIndex) {
    return locationOfSignature?.map(
      ({ position, signImg, hashSignature, timeStamp }, index) => {
        const { offsetX, offsetY, pageNumber } = position;
        if (pageIndex !== Number(pageNumber)) return null;
        return (
          <div
            key={index}
            style={{
              margin: 0,
              padding: 0,
              position: "absolute",
              top: offsetY,
              left: offsetX,
              zIndex: 100,
            }}
          >
            {CanvasSignatureComponent({
              index,
              signImg,
              hashSignature,
              timeStamp,
            })}
          </div>
        );
      }
    );
  }
  return (
    <div
      id="e-contract-doc"
      onScroll={handleScroll}
      style={{ overflow: "auto" }}
    >
      <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
        {Array.apply(null, Array(numPages))
          .map((x, i) => i + 1)
          .map((pageIndex) => (
            <div key={String(pageIndex)} className="border-solid">
              <Page
                className={`page-${pageIndex} drop-target`}
                style={{
                  flexDirection: "row",
                  backgroundColor: "#E4E4E4",
                }}
                width={width}
                onRenderSuccess={onPageRenderSuccess}
                pageIndex={pageIndex - 1}
              >
                {renderSignature(pageIndex)}
              </Page>
            </div>
          ))}
      </Document>
    </div>
  );
}
