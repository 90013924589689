import { useLocation } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  CREATE_UPLOAD_CONTRACT,
  FIND_E_CONTRACT_BY_ID,
  UPSERT_E_CONTRACT,
} from "../../../../../graphql/e-contract";
import { QUERY_ID } from "../../../../../constants/econtract.constant";
import { Form, message } from "antd";
import * as moment from "moment/moment";
import { useNavigate } from "react-router-dom";

export function FinishStep4Application(contractId, contents) {
  const navigate = useNavigate();
  const [upsertEContract] = useMutation(UPSERT_E_CONTRACT, {
    refetchQueries: [
      // {
      //   query: FIND_E_CONTRACT_BY_ID,
      //   variables: { contractId: params?.contractId },
      // },
    ],
  });

  const [currentPosition, setCurrentPosition] = useState({
    xRate: 0,
    yRate: 0,
  });

  const [currentPosition2, setCurrentPosition2] = useState({
    xRate: 0,
    yRate: 0,
  });
  const onFinish = async () => {
    console.log("contents", contents);
    console.log("currentPosition", currentPosition);
    console.log("currentPosition2", currentPosition2);
    try {
      await upsertEContract({
        variables: {
          eContractInput: {
            id: contractId,
            contents: {
              locationOfSignature: [
                {
                  name: contents?.signers[0].name,
                  email: contents?.signers[0].email,
                  position: currentPosition,
                },
                {
                  name: contents?.signers[1].name,
                  email: contents?.signers[1].email,
                  position: currentPosition2,
                },
              ],
            },
          },
        },
      });
      message.success("Update success!");
      navigate(`/e-contract/upsert/step5?id=${contractId}`);
    } catch (error) {
      message.error(error);
    }
  };
  const prev = () => {
    navigate(`/e-contract/upsert/step3?id=${contractId}`);
  };
  return { onFinish, prev, setCurrentPosition, setCurrentPosition2 };
}
