import {
  MinusCircleOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { useMutation } from "@apollo/client";
import { Button, Form, Input, message, Select, Spin, Upload } from "antd";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { CREATE_RESOURCE_INTERNAL } from "../../graphql/resourceMutations";
import {
  CREATE_SERVICE_CMS,
  FIND_ALL_SERVICE,
} from "../../graphql/service-cms";
import Editor from "../../components/Editor";
const { TextArea } = Input;

const NewService = () => {
  const [spinning, setSpinning] = useState(false);
  const navigate = useNavigate();

  const [uploadImage] = useMutation(CREATE_RESOURCE_INTERNAL);
  const [createServiceCMS] = useMutation(CREATE_SERVICE_CMS, {
    refetchQueries: [
      {
        query: FIND_ALL_SERVICE,
      },
    ],
  });

  const onFinish = async (values) => {
    setSpinning(true);
    try {
      const contentPromises = values?.contents?.map((content, index) =>
        convertContentFns(content, index)
      );
      const contents = await Promise.all(contentPromises);
      const createServiceCmInput = {
        id: values.id,
        name: values.name,
        contents,
      };
      const res = await createServiceCMS({
        variables: {
          createServiceCmInput,
        },
      });
      setSpinning(false);
      navigate("/services");
    } catch (error) {
      message.error(error);
      setSpinning(false);
      return;
    }
  };

  const normFile = (e) => {
    return e?.file;
  };
  const convertContentFns = async (content, contentIndex) => {
    let imageUrl = "";
    if (content.image) {
      const createFeatureDt = await uploadImage({
        variables: {
          file: content.image,
        },
      });
      imageUrl = createFeatureDt?.data?.createResource?.url;
    }

    return {
      id: content.id,
      name: content.name,
      elements: content.elements,
      imageUrl,
      contentIndex,
    };
  };

  return (
    <Spin tip="Đang xử lý..." spinning={spinning}>
      <div className="">
        <div>
          <p className="font-semibold	text-2xl">Tạo dịch vụ mới</p>
        </div>
        <Form
          name="new_service"
          onFinish={onFinish}
          autoComplete="off"
          layout="vertical"
        >
          <Form.Item
            name={"name"}
            label="Tên dịch vụ"
            rules={[{ required: true, message: "Thiếu" }]}
          >
            <Input showCount placeholder="Tên dịch vụ" />
          </Form.Item>
          <Form.Item
            name={"id"}
            label="Định danh dịch vụ (Lưu ý: Không thay đổi sau khi tạo)"
            rules={[{ required: true, message: "Thiếu" }]}
          >
            <Input showCount placeholder="Định danh" />
          </Form.Item>
          <>
            <div>
              <p className="text-lg	font-semibold">Nội dung</p>
            </div>
            <Form.List name="contents">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }, contentIndex) => (
                    <div key={key}>
                      <div className="flex w-full">
                        <div className="pr-2">{contentIndex + 1}.</div>
                        <Form.Item
                          className=" w-full w-8/12 border-0"
                          {...restField}
                          name={[name, "name"]}
                          rules={[{ required: true, message: "Thiếu" }]}
                        >
                          <TextArea
                            autoSize={{ minRows: 2, maxRows: 15 }}
                            showCount
                            placeholder="Nội dung"
                          />
                        </Form.Item>

                        <div className="w-1/12 pl-2">
                          <MinusCircleOutlined onClick={() => remove(name)} />
                        </div>
                      </div>
                      <Form.Item
                        className="w-5/12 border-0"
                        name={[name, "id"]}
                        label="ID (Lưu ý: Không nên thay đổi)"
                        rules={[{ required: true, message: "Thiếu" }]}
                      >
                        <Input
                          showCount
                          placeholder="Định danh thành phần con"
                        />
                      </Form.Item>

                      <Form.Item
                        {...restField}
                        className="w-10/12 border-0 flex"
                        name={[name, "image"]}
                        getValueFromEvent={normFile}
                      >
                        <Upload
                          name="logo"
                          beforeUpload={(file) => {
                            return false;
                          }}
                          listType="picture"
                          className="ml-4"
                          maxCount={1}
                        >
                          <Button icon={<UploadOutlined />}>
                            Ảnh nội dung
                          </Button>
                        </Upload>
                      </Form.Item>
                      <Form.List name={[name, "elements"]}>
                        {(
                          fields,
                          { add: addElement, remove: removeElement }
                        ) => (
                          <>
                            {fields.map(
                              ({ key, name, ...restField }, elementIndex) => {
                                return (
                                  <div key={key}>
                                    <div className="flex w-full pl-20">
                                      <div className="pl-4">
                                        {elementIndex + 1}.
                                      </div>
                                      <Form.Item
                                        {...restField}
                                        className="w-full border-0 py-2 px-2"
                                        name={[name, "element"]}
                                        rules={[
                                          {
                                            required: true,
                                            message: "Thiếu",
                                          },
                                        ]}
                                      >
                                        <Editor
                                          autoSize={{ minRows: 2, maxRows: 15 }}
                                          showCount
                                          placeholder={`Thành phần ${
                                            elementIndex + 1
                                          }`}
                                        />
                                      </Form.Item>
                                      <div className="w-2"></div>

                                      <div className="pl-2 w-1/12">
                                        <MinusCircleOutlined
                                          onClick={() => removeElement(name)}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                );
                              }
                            )}
                            <Form.Item>
                              <Button
                                className="ml-24"
                                type="dashed"
                                onClick={() => addElement()}
                                icon={<PlusOutlined />}
                              >
                                Thêm thành phần con
                              </Button>
                            </Form.Item>
                          </>
                        )}
                      </Form.List>
                    </div>
                  ))}
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      Thêm nội dung
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </>

          <Form.Item>
            <div className="flex justify-end">
              <Button type="primary" htmlType="submit">
                Lưu
              </Button>
            </div>
          </Form.Item>
        </Form>
      </div>
    </Spin>
  );
};

NewService.propTypes = {};

NewService.defaultProps = {};

export default NewService;
