import { useLocation } from "react-router-dom";
import { useEffect, useMemo } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  CREATE_UPLOAD_CONTRACT,
  FIND_E_CONTRACT_BY_ID,
  UPSERT_E_CONTRACT,
} from "../../../../../graphql/e-contract";
import { QUERY_ID } from "../../../../../constants/econtract.constant";
import { Form, message } from "antd";
import * as moment from "moment/moment";
import { useNavigate } from "react-router-dom";

export function SetFormDataStep3Application(eContract) {
  const [form] = Form.useForm();
  useEffect(() => {
    if (eContract) {
      const { contents } = eContract;
      console.log("SetFormDataStep3Application", contents);
      form.setFieldsValue({
        signers:
          contents?.signers?.map(({ email, name }) => ({
            name,
            email,
          })) ?? [],
      });
    }
  }, [eContract]);
  return {
    form,
  };
}

export function FinishStep3Application(contractId, contents) {
  const navigate = useNavigate();
  const [upsertEContract] = useMutation(UPSERT_E_CONTRACT, {
    refetchQueries: [
      // {
      //   query: FIND_E_CONTRACT_BY_ID,
      //   variables: { contractId: params?.contractId },
      // },
    ],
  });
  const onFinish = async (values) => {
    console.log("values", values);
    const { signers } = values;
    if (signers.length === 0) {
      message.warn("Vui lòng nhập thông tin người ký");
      return;
    }
    try {
      await upsertEContract({
        variables: {
          eContractInput: {
            id: contractId,
            contents: {
              signers,
            },
          },
        },
      });
      message.success("Update success!");
      navigate(`/e-contract/upsert/step4?id=${contractId}`);
    } catch (error) {
      message.error(error);
    }
  };
  const prev = () => {
    navigate(`/e-contract/upsert/step2?id=${contractId}`);
  };
  return { onFinish, prev };
}
