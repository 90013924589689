import { Button } from "antd";
import React from "react";

export default function ButtonsStep2Component({
  disabled,
  prev,
  onFinish,
  titleNext,
}) {
  return (
    <div>
      <Button className="mr-2" onClick={() => prev()}>
        Trở về
      </Button>
      <Button
        disabled={disabled}
        type="primary"
        htmlType="submit"
        onClick={() => onFinish()}
      >
        {titleNext || "Đi tiếp"}
      </Button>
    </div>
  );
}
