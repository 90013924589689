import './show-pdf-sign-on-contract.css'
import React, { useState } from 'react';
import { Document, Page } from 'react-pdf';
import CanvasSignAreaSignOnContract from "../canvas-sign-area-sign-on-contract";
export default function ShowPdfSignOnContract({ file, signImg1, setSignImg1, signImg2, setSignImg2, pageNumber, signers }) {
  const [numPages, setNumPages] = useState(null);
  const [positionCanvas1, setPositionCanvas1 ] = useState({ left: 0, top: 0 })
  const [positionCanvas2, setPositionCanvas2 ] = useState({ left: 0, top: 0 })

  async function onDocumentLoadSuccess(successEvent) {
    console.log('onDocumentLoadSuccess')
    const {numPages} = successEvent;
    setNumPages(numPages);
  }

  function onPageRenderSuccess(props) {
    const position1 = signers[0]?.locationOfSignature.position
    const left1 = position1?.offsetX
    const top1 = position1?.offsetY
    setPositionCanvas1({ top: top1, left: left1 })

    const position2 = signers[1]?.locationOfSignature.position
    const left2 = position2?.offsetX
    const top2 = position2?.offsetY
    setPositionCanvas2({ top: top2, left: left2 })
  }

  const ratio = 1.3
  const width = 595 * ratio;
  const height = 842 * ratio;

  return (
    <div id="e-contract-doc" style={{ overflow: 'auto' }}>
      <Document file={file}
                onLoadSuccess={onDocumentLoadSuccess}>
        {
          Array.apply(null, Array(numPages))
            .map((x, i)=> i + 1)
            .map(pageIndex =>
              <div key={String(pageIndex)} className='border-solid'>
                <Page
                  className={`page-${pageIndex} drop-target`}
                  width={width}
                  height={height}
                  style={{
                    flexDirection: 'row',
                    backgroundColor: '#E4E4E4',
                  }}
                  onRenderSuccess={onPageRenderSuccess}
                  pageIndex={pageIndex - 1 }
                >
                  {  pageIndex === Number(pageNumber) &&
                    <div>
                      <div style={{ margin: 0, padding: 0, position: 'absolute', top: positionCanvas1.top, left: positionCanvas1.left, zIndex: 100 }} className="signInternal">
                        <CanvasSignAreaSignOnContract signImg={signImg1} setSignImg={setSignImg1}/>
                      </div>
                      <div style={{ margin: 0, padding: 0, position: 'absolute', top: positionCanvas2.top, left: positionCanvas2.left, zIndex: 100 }} className="signInternal">
                        <CanvasSignAreaSignOnContract signImg={signImg2} setSignImg={setSignImg2}/>
                      </div>
                    </div>
                  }
                </Page>
              </div>
            )
        }
      </Document>
    </div>
  );
}
