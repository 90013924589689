import gql from "graphql-tag";

export const CREATE_SERVICE_CMS = gql`
  mutation serviceCms_create($createServiceCmInput: CreateServiceCmInput!) {
    serviceCms_create(createServiceCmInput: $createServiceCmInput) {
      id
      name
      contents
    }
  }
`;

export const CLONE_SERVICE_CMS = gql`
  mutation serviceCms_clone($cloneServiceCmInput: CloneServiceCmInput!) {
    serviceCms_clone(cloneServiceCmInput: $cloneServiceCmInput) {
      id
      name
      contents
    }
  }
`;

export const UPDATE_SERVICE_CMS = gql`
  mutation serviceCms_update($updateServiceCmInput: UpdateServiceCmInput!) {
    serviceCms_update(updateServiceCmInput: $updateServiceCmInput) {
      id
      name
      contents
    }
  }
`;

export const FIND_ONE_SERVICE_CMS = gql`
  query serviceCms_findOne($id: String!) {
    service: serviceCms_findOne(id: $id) {
      id
      name
      contents
    }
  }
`;

export const FIND_ALL_SERVICE = gql`
  query serviceCms_findAll {
    services: serviceCms_findAll {
      id
      name
      contents
    }
  }
`;
