import { Button } from "antd";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./services.css";
import ListService from "./list";
import ModalClone from "./components/modal.clone";
const Services = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  return (
    <div className="">
      <div className="flex justify-end">
        <Button
          type="primary"
          onClick={() => {
            navigate("/services/create");
          }}
        >
          Tạo mới dịch vụ
        </Button>
        <Button
          type="danger"
          onClick={() => {
            setOpen(true);
          }}
          className="ml-8"
        >
          Copy dịch vụ
        </Button>
      </div>
      <div className="mt-5">
        <ListService />
      </div>
      <ModalClone open={open} setOpen={setOpen} />
    </div>
  );
};

Services.propTypes = {};

export default Services;
