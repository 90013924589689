import { Button, Space, Spin, Table } from "antd";
import React, { useEffect, useState } from "react";

export default function TableSort({ data, columns }) {
  const [spinning, setSpinning] = useState(false);
  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});
  const handleChange = (pagination, filters, sorter) => {
    console.log("pagination", pagination);
    console.log("filters", filters);
    console.log("sorter", sorter);
    setFilteredInfo(filters);
    setSortedInfo(sorter);
  };
  const clearFilters = () => {
    setFilteredInfo({});
  };
  const clearAll = () => {
    setFilteredInfo({});
    setSortedInfo({});
  };
  const factoryFilter = (array, key) => {
    const items = array?.map((item) => item[key]);
    const uniqueItems = [...new Set(items)];
    return uniqueItems.map((item) => {
      return {
        text: item,
        value: item,
      };
    });
  };
  let _columns = columns.map((item) => {
    if (!item?.customFilter) return item;
    const key = item.key;
    return {
      ...item,
      filters: factoryFilter(data, key),
      filteredValue: filteredInfo[key] || null,
      onFilter: (value, record) => record[key].includes(value),
      sorter: (a, b) => a[key].length - b[key].length,
      sortOrder: sortedInfo.columnKey === item.key ? sortedInfo.order : null,
    };
  });
  return (
    <div>
      <Space
        style={{
          marginBottom: 16,
        }}
      >
        <Button onClick={clearFilters}>Xóa lọc</Button>
        <Button onClick={clearAll}>Xoá lọc và sắp xếp</Button>
      </Space>
      <Spin spinning={spinning}>
        <Table
          dataSource={data || []}
          columns={_columns}
          onChange={handleChange}
        />
      </Spin>
    </div>
  );
}
