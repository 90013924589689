import gql from "graphql-tag";

export const FIND_ALL_QUIZ_RESULT_MAPPINGS = gql`
  query quizMapping_findAll {
    mappings: quizMapping_findAll {
      quizId
      quiz {
        name
      }
      quizName
      description
      urlReportS3
      quizMappingId
      value
      created_at
    }
  }
`;

export const CREATE_QUIZ_RESULT_MAPPINGS = gql`
  mutation quizMapping_createQuiz(
    $createQuizMappingInput: CreateQuizMappingInput!
  ) {
    quizMapping_createQuiz(createQuizMappingInput: $createQuizMappingInput) {
      quizId
      description
      quizMappingId
      value
    }
  }
`;

export const FIND_QUIZ_RESULT_MAPPTING_BY_ID = gql`
  query quizMapping_findOne($id: String!) {
    quizMapping: quizMapping_findOne(id: $id) {
      quizId
      description
      quizMappingId
      isReport
      urlReportS3
      value
      quiz {
        quizId
        description
        name
      }
    }
  }
`;

export const UPDATE_QUIZ_MAPPING = gql`
  mutation quizMapping_update(
    $updateQuizMappingInput: UpdateQuizMappingInput!
  ) {
    quizMapping: quizMapping_update(
      updateQuizMappingInput: $updateQuizMappingInput
    ) {
      quizId
      description
      quizMappingId
      value
      isReport
      urlReportS3
      created_at
      updated_at
      quiz {
        quizId
        description
        name
        featureImageUrl
        isActive
        isPublic
        type
        created_at
        updated_at
      }
    }
  }
`;
export const REMOVE_QUIZ_MAPPING = gql`
  mutation quizMapping_remove($quizMappingId: String!) {
    quizMapping_remove(quizMappingId: $quizMappingId) {
      value
    }
  }
`;
