import React from "react";
import {
  FinishStep3Application,
  SetFormDataStep3Application,
} from "./application/step3.application";
import Step3Component from "./components/step3.component";
import { FetchDataStep1Application } from "../step1/application/step1.application";
export default function EContractUpsertStep3() {
  const { data, loading } = FetchDataStep1Application();
  console.log("data", data);
  const { form } = SetFormDataStep3Application(data?.eContract);
  const { onFinish, prev, uploadFileContactOnDrop } = FinishStep3Application(
    data?.eContract?.id,
    data?.eContract?.contents
  );
  return Step3Component({
    data,
    loading,
    form,
    onFinish,
    prev,
    uploadFileContactOnDrop,
    contractUrl: data?.eContract?.contents?.contractUrl,
  });
}
