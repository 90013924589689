import { Image, Layer, Stage, Text } from "react-konva";
import { AREA_SIGNATURE } from "../../../../../constants/econtract.constant";
import React from "react";
import moment from "moment";
import {
  getFootTextSignature,
  getHeadTextSignature,
} from "../../../../../common/econtract.util";

export default function CanvasSignatureComponent({
  index,
  signImg,
  hashSignature,
  timeStamp,
}) {
  const handleClick = (e) => {
    // logs clicked Konva.Circle instance
    console.log(e.target);
  };
  function getSignImage(src) {
    const imageSignElement = document.createElement("IMG");
    imageSignElement.src = src;
    imageSignElement.width = AREA_SIGNATURE.width;
    imageSignElement.height = AREA_SIGNATURE.height;
    return imageSignElement;
  }

  function getPreSignImage() {
    const imageSignElement = document.createElement("IMG");
    imageSignElement.src = "/line.png";
    return imageSignElement;
  }
  const fontSize = 14;
  const imageSign = getSignImage(signImg);
  const imagePreSign = getPreSignImage();
  const xSign = 20;
  return (
    <Stage
      id={`canvas-signature-${index}`}
      width={AREA_SIGNATURE.widthContainer}
      height={AREA_SIGNATURE.heightContainer}
    >
      <Layer>
        <Image
          x={xSign}
          y={xSign}
          width={AREA_SIGNATURE.width}
          height={AREA_SIGNATURE.height}
          image={imageSign}
        />
        {hashSignature && (
          <>
            <Text
              x={xSign}
              y={fontSize / 4}
              fontSize={fontSize}
              text={getHeadTextSignature(timeStamp)}
            />
            <Image
              height={AREA_SIGNATURE.heightContainer - fontSize / 2}
              width={20}
              x={0}
              y={fontSize / 4}
              image={imagePreSign}
            />
            <Text
              wrap="none"
              ellipsis={true}
              x={xSign}
              y={AREA_SIGNATURE.heightContainer - fontSize}
              width={AREA_SIGNATURE.width}
              fontSize={fontSize}
              text={getFootTextSignature(hashSignature)}
            />
          </>
        )}
      </Layer>
    </Stage>
  );
}
