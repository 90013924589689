import { Button } from "antd";
import React from "react";

export default function ButtonsStep3Component({ disabled, prev }) {
  return (
    <div>
      <Button className="mr-2" onClick={() => prev()}>
        Trở về
      </Button>
      <Button disabled={disabled} type="primary" htmlType="submit">
        Đi tiếp
      </Button>
    </div>
  );
}
