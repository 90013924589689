import gql from "graphql-tag";

export const CREATE_RESOURCE = gql`
  mutation resources_createResource($file: Upload!) {
    createResource: resources_createResource(file: $file) {
      fileName
      url
      mimetype
      encoding
    }
  }
`;

export const CREATE_RESOURCE_INTERNAL = gql`
  mutation resources_createResource($file: Upload!) {
    createResource: resources_createResource(file: $file, isInternal: true) {
      fileName
      url
      mimetype
      encoding
    }
  }
`;
