import { Button, DatePicker, Form, Input, Spin } from "antd";
import JoditEditor from "jodit-react";
import React from "react";

export default function Step1Component({ data, loading, form, onFinish }) {
  // if (!data) return <></>;
  return (
    <Spin tip="Loading..." spinning={loading}>
      <div className="mt-10">
        <Form
          name="basic"
          layout="vertical"
          form={form}
          onFinish={onFinish}
          autoComplete="off"
        >
          <div className="grid grid-cols-2 gap-4 h-96 mb-16">
            <div className="bg-white p-4">
              <Form.Item
                label="Tên tài liệu"
                name="name"
                rules={[{ required: true, message: "Nhập giá trị" }]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Số tài liệu"
                name="numberDocument"
                rules={[{ required: true, message: "Nhập giá trị!" }]}
              >
                <Input />
              </Form.Item>
            </div>
            <div className="bg-white p-4 h-96 overflow-y-auto	">
              <Form.Item label="Tóm tắt tài liệu:" name="info">
                <JoditEditor required name="info" />
              </Form.Item>
            </div>
          </div>
          <Form.Item wrapperCol={{ span: 16 }}>
            <Button type="primary" htmlType="submit">
              Đi tiếp
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Spin>
  );
}
