import React from "react";
import { FinishStep4Application } from "./application/step4.application";
import Step4Component from "./components/step4.component";
import { FetchDataStep1Application } from "../step1/application/step1.application";
export default function EContractUpsertStep4() {
  const { data, loading } = FetchDataStep1Application();
  console.log("data", data);
  const { onFinish, prev, setCurrentPosition, setCurrentPosition2 } =
    FinishStep4Application(data?.eContract?.id, data?.eContract?.contents);
  return Step4Component({
    data,
    setCurrentPosition,
    setCurrentPosition2,
    contents: data?.eContract?.contents,
    loading,
    onFinish,
    prev,
  });
}
