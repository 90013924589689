import React, {useState} from "react";
import SignCanvas2 from "../sign-canvas-2";
import './test-iframe.css'
import TestShowPdf from "../test-show-pdf";
import DraggableComponent from "./draggable.component";

export default function TestIframe () {

  const [signImg, setSignImg] = useState(null);
  const [signImgXY, setsignImgXY] = useState({ x: 99999, y: 99999 });
  console.log({signImg, signImgXY})
  return (
    <div style={{ position: 'absolute', top: 20, width: '1024px', left: "calc(50% - 1024px / 2)" }}>
      <div style={{ display: 'flex', justifyContent: 'end' }}>
        <div style={{ margin: 0, padding: 0, position: 'absolute', top: 0, left: 0, zIndex: 100 }}>
          <SignCanvas2 setSignImg={setSignImg}/>
        </div>
        {/*<div style={{ margin: 0, padding: 0, position: 'absolute', top: 240, left: 0, zIndex: -100 }} className="shadowSign">*/}
        {/*  {signImg*/}
        {/*    ? <img className="sigImage" alt='signature'*/}
        {/*           src={signImg} />*/}
        {/*    : null}*/}
        {/*</div>*/}
        {
          !signImg && <div  style={{ margin: 0, padding: 0, position: 'absolute', top: 240, left: 0, zIndex: 50 }}>
            <DraggableComponent signImg={signImg} setsignImgXY={setsignImgXY}/>
          </div>
        }
        <div style={{height: '49rem', width: '1024px', padding: '0px', margin: '0px', display: 'flex', justifyContent: 'flex-end'}}>
          <TestShowPdf file={"https://s3.ap-southeast-1.amazonaws.com/gen.dev/docs/1676109873843_GEN_TALENTS_PROFILE.pdf"} signImg={signImg} signImgXY={signImgXY} __pageNumber={window.__pageNumber} />
        </div>
      </div>
    </div>
  )

}
