import { useState } from "react";

export default function ModalApplication() {
  const INIT_STATE = {
    open: false,
    isLock: false,
  };

  const [modalProps, setModalProps] = useState(INIT_STATE);
  const showModal = () => {
    setModalProps({
      isLock: false,
      open: true,
    });
  };

  const showModalConfirm = (callback = () => {}) => {
    setModalProps({
      isLock: true,
      open: true,
    });
    callback();
  };

  const handleOk = (e) => {
    console.log(e);
    setModalProps({
      ...modalProps,
      open: false,
    });
  };
  const handleCancel = (e) => {
    console.log(e);
    setModalProps({
      ...modalProps,
      open: false,
    });
  };
  const handleModalReset = (callback = () => {}) => {
    setModalProps(INIT_STATE);
    callback();
  };
  return {
    modalProps,
    setModalProps,
    showModal,
    showModalConfirm,
    handleCancel,
    handleOk,
    handleModalReset,
  };
}
