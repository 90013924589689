import {Button, Form, Input, Select, Upload} from "antd";
import {QUIZ_API_QUESTIONS} from "../../../constants/quiz.constant";
import {UploadOutlined} from "@ant-design/icons";
import NewSurvey from "../new";

const StvtComponent = ({ normFile }) => {
  return (
    <>
      <div>
        <p className='text-lg	font-semibold'>
          Câu hỏi
        </p>
      </div>

      <Form.List name="questions">
        {() => (
          <>
            <>
              <div className='flex w-full'>
                <Form.Item
                  className='w-8/12 border-0'
                  name={[0, 'question']}
                  rules={[{ required: true, message: 'Missing question' }]}
                  initialValue={QUIZ_API_QUESTIONS.Question.Left}
                >
                  <div className='flex pr-2'>
                    <p className='pr-1'>{1}.</p>
                    <Input defaultValue={QUIZ_API_QUESTIONS.Question.Left} placeholder="Question name?" className='w-full' />
                  </div>
                </Form.Item>
                <Form.Item
                  className='w-3/12 border-0'
                  name={[0, 'type']}
                  rules={[{ required: true, message: 'Missing type question' }]}
                  initialValue={'single_question'}
                >
                  <Select
                    name="select"
                    placeholder="Type?"
                    rules={[{ required: true, message: 'Please select question type!' }]}
                    defaultValue={'single_question'}
                  >
                    <Select.Option value="single_question">Single choice</Select.Option>
                  </Select>
                </Form.Item>
              </div>
              <Form.List name={[0, 'answers']}>
                {() => (

                  <>
                    {Object.keys(QUIZ_API_QUESTIONS.Answer4.Left).map(( finger, answerIndex) => {
                      return (
                        <div
                          key={`${finger}${answerIndex}`}
                        >
                          <div className='flex w-full pl-20'>
                            <div className='pl-4'>
                              {answerIndex + 1}.
                            </div>
                            <Form.Item
                              className='w-8/12 border-0 py-2 px-2'
                              name={[answerIndex, 'answer']}
                              rules={[{ required: true, message: 'Missing answer' }]}
                              initialValue={finger}
                            >
                              <Input defaultValue={finger} placeholder={`Answer ${answerIndex + 1}`} className='w-full' />
                            </Form.Item>
                            <div className='w-2'></div>
                            <Form.Item
                              className='w-2/12 border-0 px-2'
                              name={[answerIndex, 'value']}
                              rules={[{ required: true, message: 'Missing answer value' }]}
                              initialValue={Object.values(QUIZ_API_QUESTIONS.Answer4.Left)[answerIndex].answerValue}
                            >
                              <Input defaultValue={Object.values(QUIZ_API_QUESTIONS.Answer4.Left)[answerIndex].answerValue} placeholder={`Answer ${answerIndex + 1} value`} className='w-full px-2' />
                            </Form.Item>
                          </div>
                          <div className='flex w-full pl-20 '>
                            <Form.Item
                              className='w-10/12 border-0 flex '
                              name={[answerIndex, 'image']}
                              getValueFromEvent={normFile}
                              // rules={[{ required: true, message: 'Missing answer' }]}
                              initialValue={{
                                status: 'done',
                                url: Object.values(QUIZ_API_QUESTIONS.Answer4.Left)[answerIndex].imageURL
                              }}
                            >
                              <Upload
                                name="logo"
                                beforeUpload={(file) => {
                                  return false;
                                }}
                                listType="picture"
                                className='ml-4'
                                maxCount={1}
                                defaultFileList={
                                  [{
                                    status: 'done',
                                    url: Object.values(QUIZ_API_QUESTIONS.Answer4.Left)[answerIndex].imageURL
                                  }]
                                }
                              >
                                <Button icon={<UploadOutlined />}>Answer image?</Button>
                              </Upload>
                            </Form.Item>
                          </div>
                        </div>
                      )
                    })}
                  </>
                )}
              </Form.List>
            </>

            <>
              <div className='flex w-full'>
                <Form.Item
                  className='w-8/12 border-0'
                  name={[1, 'question']}
                  rules={[{ required: true, message: 'Missing question' }]}
                  initialValue={QUIZ_API_QUESTIONS.Question.Right}
                >
                  <div className='flex pr-2'>
                    <p className='pr-1'>{1}.</p>
                    <Input defaultValue={QUIZ_API_QUESTIONS.Question.Right} placeholder="Question name?" className='w-full' />
                  </div>
                </Form.Item>
                <Form.Item
                  className='w-3/12 border-0'
                  name={[1, 'type']}
                  rules={[{ required: true, message: 'Missing type question' }]}
                  initialValue={'single_question'}
                >
                  <Select
                    name="select"
                    placeholder="Type?"
                    rules={[{ required: true, message: 'Please select question type!' }]}
                    defaultValue={'single_question'}
                  >
                    <Select.Option value="single_question">Single choice</Select.Option>
                  </Select>
                </Form.Item>
              </div>
              <Form.List name={[1, 'answers']}>
                {() => (
                  <>
                    {Object.keys(QUIZ_API_QUESTIONS.Answer4.Right).map(( finger, answerIndex) => {
                      return (
                        <div
                          key={`${finger}${answerIndex}`}
                        >
                          <div className='flex w-full pl-20'>
                            <div className='pl-4'>
                              {answerIndex + 1}.
                            </div>
                            <Form.Item
                              className='w-8/12 border-0 py-2 px-2'
                              name={[answerIndex, 'answer']}
                              rules={[{ required: true, message: 'Missing answer' }]}
                              initialValue={finger}
                            >
                              <Input defaultValue={finger} placeholder={`Answer ${answerIndex + 1}`} className='w-full' />
                            </Form.Item>
                            <div className='w-2'></div>
                            <Form.Item
                              className='w-2/12 border-0 px-2'
                              name={[answerIndex, 'value']}
                              rules={[{ required: true, message: 'Missing answer value' }]}
                              initialValue={Object.values(QUIZ_API_QUESTIONS.Answer4.Right)[answerIndex].answerValue}
                            >
                              <Input defaultValue={Object.values(QUIZ_API_QUESTIONS.Answer4.Right)[answerIndex].answerValue} placeholder={`Answer ${answerIndex + 1} value`} className='w-full px-2' />
                            </Form.Item>
                          </div>
                          <div className='flex w-full pl-20 '>
                            <Form.Item
                              className='w-10/12 border-0 flex '
                              name={[answerIndex, 'image']}
                              getValueFromEvent={normFile}
                              initialValue={{
                                status: 'done',
                                url: Object.values(QUIZ_API_QUESTIONS.Answer4.Right)[answerIndex].imageURL
                              }}
                              // rules={[{ required: true, message: 'Missing answer' }]}
                            >
                              <Upload
                                name="logo"
                                beforeUpload={(file) => {
                                  return false;
                                }}
                                listType="picture"
                                className='ml-4'
                                maxCount={1}
                                defaultFileList={
                                  [{
                                    status: 'done',
                                    url: Object.values(QUIZ_API_QUESTIONS.Answer4.Right)[answerIndex].imageURL
                                  }]
                                }
                              >
                                <Button icon={<UploadOutlined />}>Answer image?</Button>
                              </Upload>
                            </Form.Item>
                          </div>
                        </div>
                      )
                    })}
                  </>
                )}
              </Form.List>
            </>
          </>
        )}

      </Form.List>

    </>
  )
}

export default StvtComponent;
