import React from "react";
import { FinishStep7Application } from "./application/step7.application";
import Step7Component from "./components/step7.component";
import { FetchDataStep6Application } from "../step6/application/step6.application";
export default function EContractUpsertStep7() {
  const { data, loading } = FetchDataStep6Application();
  console.log("data", data);
  const { onFinish } = FinishStep7Application(
    data?.eContract?.id,
    data?.eContract?.contents
  );
  return Step7Component({
    contents: data?.eContract?.contents,
    inviteEmail: data?.eContract?.inviteEmail,
    onFinish,
  });
}
