import { useLocation } from "react-router-dom";
import { useEffect, useMemo } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  FIND_E_CONTRACT_BY_ID,
  UPSERT_E_CONTRACT,
} from "../../../../../graphql/e-contract";
import {
  ERROR_MAPPING,
  QUERY_ID,
} from "../../../../../constants/econtract.constant";
import { Form, message } from "antd";
import * as moment from "moment/moment";
import { useNavigate } from "react-router-dom";

export function FetchDataStep1Application() {
  function useQuery() {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  }
  const [findEContractById, { data, error, loading }] = useLazyQuery(
    FIND_E_CONTRACT_BY_ID
  );

  let query = useQuery();
  useEffect(() => {
    const id = query.get(QUERY_ID);
    if (id) {
      findEContractById({
        variables: {
          contractId: id,
        },
      });
    }
  }, [query.get(QUERY_ID)]);
  if (error) {
    message.error(ERROR_MAPPING[error.message] || error.message);
  }

  return { data, loading };
}

export function SetFormDataStep1Application(eContract) {
  const [form] = Form.useForm();
  useEffect(() => {
    if (eContract) {
      const { contents } = eContract;
      form.setFieldsValue({
        name: contents.name,
        info: contents.info,
        numberDocument: contents.numberDocument,
        signOnDate: moment(contents.signOnDate),
      });
    }
  }, [eContract]);
  return {
    form,
  };
}

export function FinishStep1Application(contractId) {
  const navigate = useNavigate();
  const [upsertEContract] = useMutation(UPSERT_E_CONTRACT, {
    refetchQueries: [
      // {
      //   query: FIND_E_CONTRACT_BY_ID,
      //   variables: { contractId: params?.contractId },
      // },
    ],
  });
  const onFinish = async (values) => {
    try {
      const eContract = await upsertEContract({
        variables: {
          eContractInput: {
            id: contractId,
            contents: {
              name: values.name,
              info: values.info,
              numberDocument: values.numberDocument,
              signOnDate: values.signOnDate,
            },
          },
        },
      });
      message.success("Update success!");
      navigate(
        `/e-contract/upsert/step2?id=${
          contractId || eContract?.data?.eContract?.id
        }`
      );
    } catch (error) {
      message.error(error);
    }
  };
  return { onFinish };
}
