import { Button, Form, Input, Space, Spin } from "antd";
import React from "react";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import moment from "moment";
import ButtonsStep2Component from "../../step2/components/buttons-step2.component";
import { getSignOnDate } from "../../../../../common/econtract.util";
export default function Step5Component({
  data,
  loading,
  prev,
  onFinish,
  contents,
}) {
  // if (!data) return <></>;
  return (
    <Spin spinning={loading}>
      <div className="mt-10">
        <div className="mb-2">
          <h1 className="font-bold text-lg">Xác nhận thông tin tài liệu</h1>
          <p className="font-bold text-sm mt-4">Tên tài liệu</p>
          <input className="w-full" defaultValue={contents?.name} readOnly />
          <p className="font-bold text-sm mt-4">Ngày ký</p>
          <div className="bg-white">{getSignOnDate(contents?.signOnDate)}</div>
          <p className="font-bold text-lg mt-4">Thông tin chung của tài liệu</p>
          <div
            className="bg-white"
            dangerouslySetInnerHTML={{ __html: contents?.info }}
          ></div>
          <p className="font-bold text-lg mt-4">Danh sách các bên </p>
          <div className="grid grid-cols-2">
            {contents?.signers?.map((item, idx) => {
              return (
                <div className="bg-white border p-2" key={idx}>
                  <p className="text-sm">Tên: {item.name}</p>
                  <p className="text-sm">Email: {item.email}</p>
                </div>
              );
            })}
          </div>
        </div>
        {ButtonsStep2Component({
          disabled: false,
          prev,
          onFinish,
          titleNext: "Đi tiếp (Hoàn tất)",
        })}
      </div>
    </Spin>
  );
}
