import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { useMutation, useQuery } from "@apollo/client";
import { Modal, Spin, Table, Tag, Popconfirm } from "antd";
import { useEffect, useState } from "react";
import { FIND_ALL_QUIZ, REMOVE_QUIZ } from "../../graphql/quiz";
import * as moment from "moment";
import { useNavigate } from "react-router-dom";

const ListSurvey = () => {
  const [spinning, setSpinning] = useState(false);
  const [removeQuiz] = useMutation(REMOVE_QUIZ, {
    refetchQueries: [
      {
        query: FIND_ALL_QUIZ,
        variables: { findAllQuizInput: { skip: 0, take: 999 } },
      },
    ],
    onCompleted: () => {
      setSpinning(false);
    },
  });
  const { data, loading } = useQuery(FIND_ALL_QUIZ, {
    variables: { findAllQuizInput: { skip: 0, take: 999 } },
  });
  const navigate = useNavigate();

  useEffect(() => {
    setSpinning(loading);
  }, [loading]);

  const columns = [
    {
      title: "No.",
      key: "index",
      width: "5%",
      render: (_value, _item, index) => index + 1,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "25%",
      sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: (
        <Popconfirm
          title="Bạn có muốn unpublic survey này không？"
          okText="Yes"
          cancelText="No"
        >
          {" "}
          <a href="#" className="text-black">
            Public
          </a>
        </Popconfirm>
      ),
      dataIndex: "public",
      key: "public",
      width: "15%",
      render: (value) => {
        if (value) {
          return <Tag color="success">TRUE</Tag>;
        }
        return <Tag color="warning">FALSE</Tag>;
      },
    },
    {
      title: "Created at",
      dataIndex: "created_at",
      key: "created_at",
      render: (created_at) =>
        moment(Date.parse(created_at)).format("DD/MM/YYYY hh:mm:ss"),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: "10%",
      render: (_value, item) => {
        return (
          <>
            <EditOutlined onClick={() => handleUpdate(item)} className="mr10" />
            <DeleteOutlined
              onClick={() => handleDelete(item)}
              className="mr10"
            />
          </>
        );
      },
    },
  ];

  const handleUpdate = (item) => {
    navigate(`/surveys/edit/${item.quizId}`);
  };

  const handleDelete = (item) => {
    Modal.confirm({
      title: `Delete survey`,
      icon: <ExclamationCircleOutlined />,
      content: `Are you sure delete this survey "${item.name}"?`,
      okText: "Delete",
      cancelText: "Cancel",
      onOk: () => {
        setSpinning(true);
        removeQuiz({
          variables: {
            id: item.quizId,
          },
        });
      },
    });
  };

  return (
    <div>
      <Spin spinning={spinning}>
        <Table dataSource={data?.quizs || []} columns={columns} />
      </Spin>
    </div>
  );
};

ListSurvey.propTypes = {};

ListSurvey.defaultProps = {};

export default ListSurvey;
