import { Button } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import ListReferral from "./list";

export default function Referral() {
  const navigate = useNavigate();

  return (
    <div className="">
      <div className="mt-5">
        <ListReferral />
      </div>
    </div>
  );
}

Referral.propTypes = {};
