export const QUERY_ID = "id";
export const QUERY_TOKEN = "token";

export const PAGE_PDF_SIZE = {
  width: 612,
  height: 792,
};

export const AREA_SIGNATURE = {
  widthContainer: 180,
  heightContainer: 180,
  width: 140,
  height: 140,
  widthPx: "140px",
  heightPx: "140px",
  fontSize: 14,
  padding: 20,
};

export const ERROR_MAPPING = {
  ERROR_ECONTRACT_NOT_EXIST: "Lỗi không tồn tại hợp đồng",
};
