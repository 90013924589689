import { useLocation } from "react-router-dom";
import { useEffect, useMemo } from "react";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  CREATE_UPLOAD_CONTRACT,
  FIND_ALL_E_CONTRACT,
  FIND_E_CONTRACT_BY_ID,
  UPSERT_E_CONTRACT,
  VERIFY_E_CONTRACT_BY_TOKEN,
} from "../../../../../graphql/e-contract";
import {
  QUERY_ID,
  QUERY_TOKEN,
} from "../../../../../constants/econtract.constant";
import { Form, message } from "antd";
import * as moment from "moment/moment";
import { useNavigate } from "react-router-dom";

export function FinishStep5Application(contractId, contents) {
  const navigate = useNavigate();
  const [upsertEContract] = useMutation(UPSERT_E_CONTRACT, {
    refetchQueries: [
      {
        query: FIND_ALL_E_CONTRACT,
        variables: { findAllEContractInput: { skip: 0, take: 999 } },
      },
    ],
  });
  const counterInvite = contents?.counterInvite || 0;
  const onFinish = async () => {
    try {
      await upsertEContract({
        variables: {
          eContractInput: {
            id: contractId,
            contents: {
              step: 5,
              counterInvite: counterInvite + 1,
            },
          },
        },
      });
      message.success("Update success!");
      navigate(`/e-contract`);
    } catch (error) {
      message.error(error);
    }
  };
  const prev = () => {
    navigate(`/e-contract/upsert/step4?id=${contractId}`);
  };
  return { onFinish, prev };
}
