import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { useMutation, useQuery } from "@apollo/client";
import { Modal, Spin, Table } from "antd";
import React, { useEffect, useState } from "react";
import * as moment from "moment";
import {
  FIND_ALL_QUIZ_RESULT_MAPPINGS,
  REMOVE_QUIZ_MAPPING,
} from "../../graphql/quiz-result-mappting";
import EditQuizResultMappings from "./edit";
import TableSort from "../../components/TableSort";

const ListQuizResultMapping = ({ loadingProps }) => {
  const [spinning, setSpinning] = useState(false);
  const [isShowModalUpdate, setIsShowModalUpdate] = useState(false);
  const [quizMappingId, setQuizMappingId] = useState();
  const [removeQuizResultMapping] = useMutation(REMOVE_QUIZ_MAPPING, {
    refetchQueries: [{ query: FIND_ALL_QUIZ_RESULT_MAPPINGS }],
    onCompleted: () => {
      setSpinning(false);
    },
  });
  const { data, loading } = useQuery(FIND_ALL_QUIZ_RESULT_MAPPINGS);

  useEffect(() => {
    setSpinning(loading);
  }, [loading]);

  const columns = [
    {
      title: "No.",
      key: "index",
      width: "5%",
      render: (_value, _item, index) => index + 1,
    },
    {
      title: "Title",
      dataIndex: "quizName",
      key: "quizName",
      width: "20%",
      customFilter: true,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: "35%",
      sorter: (a, b) => a.description.length - b.description.length,
    },
    {
      title: "Value",
      dataIndex: "value",
      key: "value",
      width: "10%",
      customFilter: true,
    },
    {
      title: "Created at",
      dataIndex: "created_at",
      key: "created_at",
      render: (created_at) =>
        moment(Date.parse(created_at)).format("DD/MM/YYYY hh:mm:ss"),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: "10%",
      render: (_value, item) => {
        return (
          <>
            <EditOutlined onClick={() => handleUpdate(item)} className="mr10" />
            <DeleteOutlined
              onClick={() => handleDelete(item)}
              className="mr10"
            />
          </>
        );
      },
    },
  ];

  const handleUpdate = (item) => {
    setQuizMappingId(item.quizMappingId);
    setIsShowModalUpdate(true);
  };

  const handleDelete = (item) => {
    Modal.confirm({
      title: `Delete survey`,
      icon: <ExclamationCircleOutlined />,
      content: `Are you sure delete this survey "${item.name}"?`,
      okText: "Delete",
      cancelText: "Cancel",
      onOk: () => {
        setSpinning(true);
        removeQuizResultMapping({
          variables: {
            quizMappingId: item.quizMappingId,
          },
        });
      },
    });
  };

  return (
    <div>
      {/*<Spin spinning={spinning || loadingProps}>*/}
      {/*  <Table dataSource={data?.mappings || []} columns={columns} />*/}
      {/*</Spin>*/}
      <TableSort data={data?.mappings} columns={columns} />;
      <EditQuizResultMappings
        isShowModalUpdate={isShowModalUpdate}
        setIsShowModalUpdate={setIsShowModalUpdate}
        quizMappingId={quizMappingId}
        dataList={data}
      />
    </div>
  );
};

ListQuizResultMapping.propTypes = {};

ListQuizResultMapping.defaultProps = {};

export default ListQuizResultMapping;
