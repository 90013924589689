import gql from "graphql-tag";

export const FIND_ALL_REFERRAL = gql`
  query referral_findAll($findAllReferralInput: FindAllReferralInput!) {
    referrals: referral_findAll(findAllReferralInput: $findAllReferralInput) {
      id
      referralCode
      isResolved
      quizId
      quizName
      email
      fullName
      dayOfBirth
      email
      phoneNumber
      created_at
      updated_at
    }
  }
`;

export const REMOVE_REFERRAL = gql`
  mutation referral_removeReferral($id: Int!) {
    referral_removeReferral(id: $id) {
      referralCode
    }
  }
`;

export const UPDATE_REFERRAL = gql`
  mutation quiz_updateQuiz($updateQuizInput: UpdateQuizInput!) {
    quiz: quiz_updateQuiz(updateQuizInput: $updateQuizInput) {
      quizId
      description
      benefit
      descriptionLong
      name
      submenu
      isPublic
      questions {
        imageUrl
        question
        type
        questionIndex
        questionId
        answers {
          answer
          answerId
          answerIndex
          value
          imageUrl
        }
      }
    }
  }
`;

export const FIND_REFERRAL_BY_ID = gql`
  query quiz_findOne($id: String!) {
    quiz: quiz_findOne(id: $id) {
      quizId
      description
      benefit
      descriptionLong
      name
      isPublic
      type
      submenu
      featureImageUrl
      questions {
        imageUrl
        question
        type
        questionIndex
        questionId
        answers {
          answer
          answerId
          value
          answerIndex
          imageUrl
        }
      }
    }
  }
`;
