import { Document, Page } from "react-pdf";
import { useState } from "react";

export default function DocumentContract({ url }) {
  const [numPages, setNumPages] = useState(0);
  console.log("DocumentContract", url);
  if (!url) return <></>;
  return (
    <div>
      <Document
        file={url}
        onLoadSuccess={({ numPages }) => setNumPages(numPages)}
      >
        {Array.apply(null, Array(numPages))
          .map((x, i) => i + 1)
          .map((pageIndex) => (
            <div key={Number(pageIndex)} className="border-solid	">
              <Page
                size="A4"
                style={{
                  flexDirection: "row",
                  backgroundColor: "#E4E4E4",
                }}
                pageIndex={pageIndex - 1}
              />
            </div>
          ))}
      </Document>
    </div>
  );
}
