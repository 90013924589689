import React, { useState } from 'react';
import { Button } from 'antd';
import CreateQuizResultMapping from './create';
import ListQuizResultMapping from './list';

const QuizResultMappings = () => {
  const [isShowModalCreate, setIsShowModalCreate] = useState(false);
  const [loading, setLoading] = useState(false);

  return <div className=''>
    <div className='flex justify-end w-full h-10'>
      <Button 
        type='primary'
        onClick={() => {
          setIsShowModalCreate(true);
        }}
      >
        Create
      </Button>
    </div>
    <div className='mt-5'>
      <ListQuizResultMapping 
        loadingProps={loading}
      />
    </div>
    <CreateQuizResultMapping
      isShowModalCreate={isShowModalCreate}
      setIsShowModalCreate={setIsShowModalCreate}
      setLoading={setLoading}
    />
  </div>;
};

QuizResultMappings.propTypes = {};

export default QuizResultMappings;
