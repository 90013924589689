import gql from 'graphql-tag';


export const LOGIN = gql`
	query authenticate(
		$input: AuthenticateInput!
	) {
		authenticate: auth_authenticate(
			input: $input
		) {
			email
			fullname
			userId
			accessToken
			role
		}
	}
`;

export const GET_PROFILE = gql`
	query users_getProfile{
		getProfile: users_getProfile{
			userId
			email
			fullname
			userId
			phone
			role
		}
	}
`;


export const FORGOT_PASSWORD = gql`
	mutation forgotPassword($email: String!){
		forgotPassword: auth_forgotPassword(email: $email)
	}
`;

